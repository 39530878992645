var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-gutters market-report-container"},[_c('div',{},[_c('table',{staticClass:"table table-striped"},[(_vm.regulatedTableData.length)?_c('thead',[_c('tr',[_c('th',{attrs:{"width":_vm.canShowProducts ? '5%' : '10%'}},[_vm._v("Rank")]),_c('th',{attrs:{"width":_vm.canShowProducts ? '20%' : '30%'},on:{"click":function($event){return _vm.sortByColumn('vendor_name')}}},[_vm._v(" Vendor Name "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('vendor_name')
                ]})])]),(_vm.canShowProducts)?_c('th',{attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('product_name')}}},[_vm._v(" Product Name "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('product_name')
                ]})])]):_vm._e(),_c('th',{staticClass:"text-right",attrs:{"width":"10%"},on:{"click":function($event){return _vm.sortByColumn('bank_app_rating')}}},[_vm._v(" Bank "+_vm._s(_vm.headerType(1))+" "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('bank_app_rating')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"10%"},on:{"click":function($event){return _vm.sortByColumn('cu_app_rating')}}},[_vm._v(" CU "+_vm._s(_vm.headerType(2))+" "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('cu_app_rating')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"10%"},on:{"click":function($event){return _vm.sortByColumn('diff_app_rating')}}},[_vm._v(" "+_vm._s(_vm.headerType(3))+" Differential "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('diff_app_rating')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.headerType(4))+" ")])])]):_vm._e(),(_vm.regulatedTableData.length)?_c('tbody',{ref:"reportTableBody"},[_vm._l((_vm.regulatedTableData),function(data,rank){return _c('tr',{key:rank},[_c('td',{},[_vm._v(" "+_vm._s(rank + 1)+" ")]),_c('td',{staticClass:"name-column",attrs:{"title":data.vendor}},[_vm._v(" "+_vm._s(data.vendor_name)+" ")]),(_vm.canShowProducts)?_c('td',{staticClass:"name-column",attrs:{"title":data.product_name}},[_vm._v(" "+_vm._s(data.product_name)+" ")]):_vm._e(),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.bank_app_rating,_vm.dataFormat.bank_app_rating))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.cu_app_rating,_vm.dataFormat.cu_app_rating))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.diff_app_rating,_vm.dataFormat.diff_app_rating))+" ")]),(rank === 0)?_c('td',{staticClass:"p-0",attrs:{"rowspan":_vm.regulatedTableData.length + 1}},[(_vm.showChart)?_c('div',{ref:"reportChartContainer",refInFor:true,staticClass:"report-chart-container"},[_c('div',{attrs:{"id":"report-chart-vasft"}})]):_vm._e()]):_vm._e()])}),_c('tr',{staticClass:"report-footer"},[_c('td',{attrs:{"colspan":_vm.canShowProducts ? '3' : '2'}},[_vm._v("Grand Total")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(parseFloat(_vm.reportTotal.bank_app_rating),_vm.dataFormat.bank_app_rating))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(parseFloat(_vm.reportTotal.cu_app_rating),_vm.dataFormat.cu_app_rating))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(parseFloat(_vm.reportTotal.diff_app_rating),_vm.dataFormat.diff_app_rating))+" ")])])],2):_c('tbody',[_vm._m(0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('div',{staticClass:"text-center text-muted"},[_vm._v("No Records found")])])])}]

export { render, staticRenderFns }