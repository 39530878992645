<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped small-font-for-print">
        <thead v-if="regulatedTableData.length">
          <tr>
            <th @click="sortByColumn('vendor_name')" width="10%">
              Vendor Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('vendor_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('product_name')"
              width="10%"
              v-if="canShowProducts"
            >
              Product Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('product_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('market_total')"
              class="text-right"
              width="10%"
            >
              Market Total
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('market_total')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('percentage_of_market')"
              class="text-right"
              width="10%"
            >
              Market Share Current
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('percentage_of_market')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('avg_total_assets')"
              class="text-right"
              width="10%"
            >
              Avg. Total Assets
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('avg_total_assets')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('avg_dep_accounts')"
              class="text-right"
              width="10%"
            >
              Avg. Dep Accounts
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('avg_dep_accounts')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('avg_branches')"
              class="text-right"
              width="10%"
            >
              Avg. Branches
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('avg_branches')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('enrolled_customers')"
              class="text-right"
              width="10%"
            >
              Avg. Enrolled Customers
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('enrolled_customers')
                  ]"
                >
                </i>
              </span>
            </th>
            <th @click="sortByColumn('avg_roa')" class="text-right" width="10%">
              Return on Assets
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('avg_roa')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('avg_accounts_growth')"
              class="text-right"
              width="10%"
            >
              Dep Acct Growth - 1 Year
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('avg_accounts_growth')
                  ]"
                >
                </i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="regulatedTableData.length">
          <tr v-for="(data, rank) in regulatedTableData" :key="rank">
            <td class="name-column" :title="data.vendor_name">
              {{ data.vendor_name }}
            </td>
            <td
              class="name-column"
              :title="data.product_name"
              v-if="canShowProducts"
            >
              {{ data.product_name }}
            </td>
            <td class="text-right">
              {{ data.market_total | formatValue(dataFormat.market_total) }}
            </td>
            <td class="text-right">
              {{
                data.percentage_of_market
                  | formatValue(dataFormat.percentage_of_market)
              }}
            </td>
            <td class="text-right">
              {{
                data.avg_total_assets | formatValue(dataFormat.avg_total_assets)
              }}
            </td>
            <td class="text-right">
              {{
                data.avg_dep_accounts | formatValue(dataFormat.avg_dep_accounts)
              }}
            </td>
            <td class="text-right">
              {{ data.avg_branches | formatValue(dataFormat.avg_branches) }}
            </td>
            <td class="text-right">
              {{
                data.enrolled_customers
                  | formatValue(dataFormat.enrolled_customers)
              }}
            </td>
            <td class="text-right">
              {{ data.avg_roa | formatValue(dataFormat.avg_roa) }}
            </td>
            <td class="text-right">
              {{
                data.avg_accounts_growth
                  | formatValue(dataFormat.avg_accounts_growth)
              }}
            </td>
          </tr>
          <tr class="report-footer">
            <td :colspan="canShowProducts ? 2 : 1">Grand Total</td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.market_total)
                  | formatValue(dataFormat.market_total)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.percentage_of_market)
                  | formatValue(dataFormat.percentage_of_market)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.avg_total_assets)
                  | formatValue(dataFormat.avg_total_assets)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.avg_dep_accounts)
                  | formatValue(dataFormat.avg_dep_accounts)
              }}
            </td>
            <td class="text-right">
              {{
                reportTotal.avg_branches | formatValue(dataFormat.avg_branches)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.enrolled_customers)
                  | formatValue(dataFormat.enrolled_customers)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.avg_roa)
                  | formatValue(dataFormat.avg_roa)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.avg_accounts_growth)
                  | formatValue(dataFormat.avg_accounts_growth)
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
// global
import { mapState, mapGetters, mapMutations } from 'vuex'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'VAClientFirmographicDetails',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    },
    reportOtherData: {
      required: true,
      default: null
    },
    reportTotal: {
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      sortOrder: {
        vendor_name: ['none', 'string'],
        product_name: ['none', 'string'],
        market_total: ['none', 'float'],
        percentage_of_market: ['none', 'float'],
        avg_total_assets: ['none', 'float'],
        avg_dep_accounts: ['none', 'float'],
        avg_branches: ['none', 'float'],
        enrolled_customers: ['none', 'float'],
        avg_roa: ['none', 'float'],
        avg_accounts_growth: ['none', 'float']
      }
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  computed: {
    ...mapState('DigitalChannels', ['topVendors', 'topVendorsMax']),
    ...mapGetters('DigitalChannels', ['canShowProducts', 'reportSubTitle']),
    regulatedTableData() {
      let regulatedData = this.reportData ? [...this.reportData] : []

      if (this.reportOtherData && this.reportData.length < this.topVendorsMax) {
        regulatedData.push(this.reportOtherData)
      }

      return regulatedData
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'Vendor Name',
        { value: 'Market Total', format: { alignment: 'right' } },
        { value: 'Market Share Current', format: { alignment: 'right' } },
        { value: 'Avg. Total Assets', format: { alignment: 'right' } },
        { value: 'Avg. Dep Accounts', format: { alignment: 'right' } },
        { value: 'Avg.Branches', format: { alignment: 'right' } },
        { value: 'Avg. Enrolled Customers', format: { alignment: 'right' } },
        { value: 'Return on Assets', format: { alignment: 'right' } },
        { value: 'Dep Acct Growth - 1 Year', format: { alignment: 'right' } }
      ]
      if (this.canShowProducts) headers.splice(1, 0, 'Product Name')
      downloadReportRows.push(headers)
      const combinedData = [...this.regulatedTableData, this.reportTotal]
      //Report data
      combinedData.map(row => {
        let data = []

        data.push(row['vendor_name'])
        if (this.canShowProducts) {
          data.push(row['product_name'] ? row['product_name'] : '')
        }
        data.push({
          value: row['market_total'],
          format: this.dataFormat['market_total']
        })
        data.push({
          value: row['percentage_of_market'],
          format: this.dataFormat['percentage_of_market']
        })
        data.push({
          value: row['avg_total_assets'],
          format: this.dataFormat['avg_total_assets']
        })
        data.push({
          value: row['avg_dep_accounts'],
          format: this.dataFormat['avg_dep_accounts']
        })
        data.push({
          value: row['avg_branches'],
          format: this.dataFormat['avg_branches']
        })
        data.push({
          value: row['enrolled_customers'],
          format: this.dataFormat['enrolled_customers']
        })
        data.push({
          value: row['avg_roa'],
          format: this.dataFormat['avg_roa']
        })
        data.push({
          value: row['avg_accounts_growth'],
          format: this.dataFormat['avg_accounts_growth']
        })
        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name:
          'Retail Mobile Banking Vendor Analytics - Client Firmographic Details',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
