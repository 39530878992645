<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="regulatedTableData.length">
          <tr class="pb-0">
            <td colspan="3" class="pb-0"></td>
            <td colspan="4" class="text-center pb-0"><b>Feature Segment</b></td>
            <th rowspan="2" class="pb-0">
              <div class="" width="30%">
                <div class="text-left">
                  <span class="bar-legend basic"></span>
                  <span class="px-2">Basic</span>
                </div>
                <div class="text-left">
                  <span class="bar-legend standard"></span>
                  <span class="px-2">Standard</span>
                </div>
                <div class="text-left">
                  <span class="bar-legend advanced"></span>
                  <span class="px-2">Advanced</span>
                </div>
                <div class="text-left">
                  <span class="bar-legend leading"></span>
                  <span class="px-2">Leading</span>
                </div>
              </div>
            </th>
          </tr>
          <tr class="pt-0">
            <th @click="sortByColumn('name')" width="10%" class="pt-0">
              FI Asset Group
              <span>
                <i
                  :class="['fa fa-regular sort-icon', getSortIconClass('name')]"
                >
                </i>
              </span>
            </th>

            <th
              @click="sortByColumn('market_total')"
              class="text-right no-wrap pt-0"
              width="9%"
            >
              Market Total
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('market_total')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('percentage_of_market')"
              class="text-right no-wrap pt-0"
              width="9%"
            >
              % of Market
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('percentage_of_market')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('basic')"
              class="text-right pt-0"
              width="10%"
            >
              Basic
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('basic')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('standard')"
              class="text-right pt-0"
              width="10%"
            >
              Standard
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('standard')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('advanced')"
              class="text-right pt-0"
              width="12%"
            >
              Advanced
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('advanced')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('leading')"
              class="text-right pt-0"
              width="10%"
            >
              Leading
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('leading')
                  ]"
                >
                </i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="regulatedTableData.length">
          <tr v-for="(data, index) in regulatedTableData" :key="index">
            <td class="name-column font-weight-bold">
              {{ data.name }}
            </td>
            <td class="text-right">
              {{ data.market_total | numberFormat }}
            </td>
            <td class="text-right">
              {{
                data.percentage_of_market
                  | formatValue(dataFormat.percentage_of_market || 'P1')
              }}
            </td>
            <td class="text-right">
              {{ data.basic | formatValue(dataFormat.basic || 'P1') }}
            </td>
            <td class="text-right">
              {{ data.standard | formatValue(dataFormat.standard || 'P1') }}
            </td>
            <td class="text-right">
              {{ data.advanced | formatValue(dataFormat.advanced || 'P1') }}
            </td>
            <td class="text-right">
              {{ data.leading | formatValue(dataFormat.leading || 'P1') }}
            </td>
            <td class="p-0" :rowspan="reportData.length">
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-iafs"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td class="name-column font-weight-bold">
              Grand Total
            </td>
            <td class="text-right">
              {{ grandTotal.market_total | numberFormat }}
            </td>
            <td class="text-right">
              {{
                grandTotal.percentage_of_market
                  | formatValue(dataFormat.percentage_of_market || 'P1')
              }}
            </td>
            <td class="text-right">
              {{ grandTotal.basic | formatValue(dataFormat.basic || 'P1') }}
            </td>
            <td class="text-right">
              {{
                grandTotal.standard | formatValue(dataFormat.standard || 'P1')
              }}
            </td>
            <td class="text-right">
              {{
                grandTotal.advanced | formatValue(dataFormat.advanced || 'P1')
              }}
            </td>
            <td class="text-right">
              {{ grandTotal.leading | formatValue(dataFormat.leading || 'P1') }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
// global
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'IAFeatureSegments',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        name: ['none', 'asset_group'],
        market_total: ['none', 'float'],
        percentage_of_market: ['none', 'float'],
        basic: ['none', 'float'],
        standard: ['none', 'float'],
        advanced: ['none', 'float'],
        leading: ['none', 'float']
      }
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  computed: {
    ...mapGetters('DigitalChannels', ['reportSubTitle'])
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),

    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-iafs'
        ) {
          chart.destroy()
        }
      })
      Highcharts.chart('report-chart-iafs', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0,
            stacking: 'normal'
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          max: 1,
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(this.value, 'P0')
            }
          },
          title: {
            text: null
          }
        },
        series: [
          {
            data: this.regulatedTableData.map(rd => parseFloat(rd.basic)),
            color: '#ace266',
            pointPlacement: this.pointPlacementValue()
          },
          {
            data: this.regulatedTableData.map(rd => parseFloat(rd.standard)),
            color: '#8cc63f',
            pointPlacement: this.pointPlacementValue()
          },
          {
            data: this.regulatedTableData.map(rd => parseFloat(rd.advanced)),
            color: '#6fab20',
            pointPlacement: this.pointPlacementValue()
          },
          {
            data: this.regulatedTableData.map(rd => parseFloat(rd.leading)),
            color: '#52880b',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'FI Asset Group',
        'Market Total',
        '% of Market',
        'Basic',
        'Standard',
        'Advanced',
        'Leading'
      ]

      downloadReportRows.push(headers)
      const combinedData = [...this.regulatedTableData, this.grandTotal]
      //Report data
      combinedData.map(row => {
        let data = []
        data.push(row['name'])
        data.push(row['market_total'])

        data.push({
          value: row['percentage_of_market'],
          format: this.dataFormat['percentage_of_market'] || 'P1'
        })
        data.push({
          value: row['basic'],
          format: this.dataFormat['basic'] || 'P1'
        })
        data.push({
          value: row['standard'],
          format: this.dataFormat['standard'] || 'P1'
        })
        data.push({
          value: row['advanced'],
          format: this.dataFormat['advanced'] || 'P1'
        })
        data.push({
          value: row['leading'],
          format: this.dataFormat['leading'] || 'P1'
        })

        downloadReportRows.push(data)
      })
      this.setDownloadDCReportData({
        name: 'Retail Mobile Banking Institution Analytics - Feature Segments',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
