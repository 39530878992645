<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="reportTableData.length">
          <tr>
            <td colspan="2"></td>
            <th colspan="7" class="text-center">FI Asset Group</th>
          </tr>
          <tr>
            <th width="25%">Group Name</th>
            <th width="25%">Name</th>
            <th
              width="7%"
              class="text-right"
              v-for="(header, periodIndex) in reportHeaders"
              :key="'subHead_' + periodIndex"
            >
              {{ header }}
            </th>
          </tr>
          <tr>
            <th colspan="2"></th>
            <th
              class="text-right"
              v-for="(header, index) in Object.keys(reportHeaders)"
              :key="'total' + index"
            >
              {{ reportTotal[header] | formatValue('N0') }}
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="reportTableData.length">
          <tr v-for="(data, rowIndex) in reportTableData" :key="rowIndex">
            <td class="name-column font-weight-bold">
              {{ getGroupName(rowIndex) }}
            </td>
            <td>
              {{ data.name }}
            </td>
            <td
              class="text-right"
              v-for="(header, index) in Object.keys(reportHeaders)"
              :key="'data' + rowIndex + '_' + index"
            >
              {{ data[header] | formatValue('P2') }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'IAFeatures',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    },
    reportHeaders: {
      required: true,
      default: []
    }
  },
  data() {
    return {
      showChart: true
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  updated() {
    this.prepareDownloadReport()
  },
  computed: {
    ...mapGetters('DigitalChannels', ['reportSubTitle']),
    reportTableData() {
      return this.reportData.filter(data => data.name !== 'Grand Total')
    },

    reportTotal() {
      return this.reportData.find(data => data.name === 'Grand Total')
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    getGroupName(current) {
      if (current - 1 < 0) return this.reportTableData[current].group_name
      else if (
        !this.reportTableData[current] ||
        this.reportTableData[current].group_name ===
          this.reportTableData[current - 1].group_name
      )
        return ''
      else return this.reportTableData[current].group_name
    },

    prepareDownloadReport() {
      let downloadReportRows = []
      //year headers
      let yearHeaders = []
      yearHeaders.push({ value: '', colSpan: 1 })
      yearHeaders.push({ value: 'FI Asset Group', colSpan: 7 })
      //Headers
      let headers = ['Group Name', 'Name']
      Object.values(this.reportHeaders).map(header => {
        headers.push(header)
      })
      downloadReportRows.push(headers)
      //Grand Total
      let total = ['', '']
      Object.keys(this.reportHeaders).map(header => {
        total.push({ value: this.reportTotal[header], format: '#,###' })
      })
      downloadReportRows.push(total)
      const groupName = this.getGroupName
      //Report data
      this.reportTableData.map((row, index) => {
        let data = []
        data.push(groupName(index))
        data.push(row.name)
        Object.keys(this.reportHeaders).map(header => {
          data.push({ value: row[header], format: 'P2' })
        })
        downloadReportRows.push(data)
      })
      this.setDownloadDCReportData({
        name: 'Retail Mobile Banking Institution Analytics - Features',
        subTitle: this.reportSubTitle,
        yearHeaders: yearHeaders,
        data: downloadReportRows
      })
    }
  }
}
</script>
