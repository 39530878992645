<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="reportTableData.length">
          <tr>
            <td width="15%"></td>
            <td
              colspan="2"
              class="left-border text-center no-bottom-padding"
              width="30%"
            >
              <b>Banks</b>
            </td>
            <td
              colspan="2"
              class="left-border text-center no-bottom-padding "
              width="30%"
            >
              <b>Credit Unions</b>
            </td>
            <th class="text-right left-border" width="25%" rowspan="2">
              <div class="chart-container-header">
                <div class="text-left">
                  <div class="text-left">
                    <span class="bar-legend lifetime-installs"></span>
                    <span class="px-2">Banks</span>
                  </div>
                  <div class="text-left">
                    <span class="bar-legend enrollment "></span>
                    <span class="px-2">Credit Unions</span>
                  </div>
                </div>
                <div class="text-right">
                  <span class="px-2">Total Churn Rate</span>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th @click="sortByColumn('name')" width="15%">
              FI Asset Group
              <span>
                <i
                  :class="['fa fa-regular sort-icon', getSortIconClass('name')]"
                >
                </i>
              </span>
            </th>

            <th
              @click="sortByColumn('bank_competitive_churn_rate')"
              class="text-right left-border"
              width="15%"
            >
              Competitive Churn Rate
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('bank_competitive_churn_rate')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('bank_consolidation_churn_rate')"
              class="text-right"
              width="15%"
            >
              Consolidation Churn Rate
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('bank_consolidation_churn_rate')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('cu_competitive_churn_rate')"
              class="text-right left-border"
              width="15%"
            >
              Competitive Churn Rate
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('cu_competitive_churn_rate')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('cu_consolidation_churn_rate')"
              class="text-right"
              width="15%"
            >
              Consolidation Churn Rate
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('cu_consolidation_churn_rate')
                  ]"
                >
                </i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="reportTableData.length">
          <tr v-for="(data, asset_group) in reportTableData" :key="asset_group">
            <td class="name-column font-weight-bold">
              {{ data.name }}
            </td>
            <td class="text-right left-border">
              {{
                data.bank_competitive_churn_rate
                  | formatValue(dataFormat.bank_competitive_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                data.bank_consolidation_churn_rate
                  | formatValue(dataFormat.bank_consolidation_churn_rate)
              }}
            </td>
            <td class="text-right left-border">
              {{
                data.cu_competitive_churn_rate
                  | formatValue(dataFormat.cu_competitive_churn_rate)
              }}
            </td>
            <td class="text-right right-border">
              {{
                data.cu_consolidation_churn_rate
                  | formatValue(dataFormat.cu_consolidation_churn_rate)
              }}
            </td>

            <td class="p-0" :rowspan="reportData.length">
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-iacrt"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td>Grand Total</td>
            <td class="text-right left-border">
              {{
                reportTotal.bank_competitive_churn_rate
                  | formatValue(dataFormat.bank_competitive_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                reportTotal.bank_consolidation_churn_rate
                  | formatValue(dataFormat.bank_consolidation_churn_rate)
              }}
            </td>
            <td class="text-right left-border">
              {{
                reportTotal.cu_competitive_churn_rate
                  | formatValue(dataFormat.cu_competitive_churn_rate)
              }}
            </td>
            <td class="text-right right-border">
              {{
                reportTotal.cu_consolidation_churn_rate
                  | formatValue(dataFormat.cu_consolidation_churn_rate)
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
// global
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'IAChurnRateType',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        name: ['none', 'asset_group'],
        bank_competitive_churn_rate: ['none', 'float'],
        bank_consolidation_churn_rate: ['none', 'float'],
        cu_competitive_churn_rate: ['none', 'float'],
        cu_consolidation_churn_rate: ['none', 'float']
      }
    }
  },
  computed: {
    ...mapGetters('DigitalChannels', ['reportSubTitle']),
    reportTableData() {
      return this.reportData.filter(data => data.name !== 'Grand Total')
    },

    reportTotal() {
      return this.reportData.find(data => data.name === 'Grand Total')
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-iacrt'
        ) {
          chart.destroy()
        }
      })
      const dataFormat = this.dataFormat
      Highcharts.chart('report-chart-iacrt', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(
                this.value,
                dataFormat.bank_competitive_churn_rate
              )
            }
          }
        },
        series: [
          {
            data: this.reportTableData.map(
              rd =>
                parseFloat(rd.cu_competitive_churn_rate) +
                parseFloat(rd.cu_consolidation_churn_rate)
            ),
            color: '#333333',
            pointPlacement: this.pointPlacementValue()
          },
          {
            data: this.reportTableData.map(
              rd =>
                parseFloat(rd.bank_competitive_churn_rate) +
                parseFloat(rd.bank_consolidation_churn_rate)
            ),
            color: '#A7CA77',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []
      //year Headers
      let yearHeaders = []
      yearHeaders.push({ value: 'Banks', colSpan: 2 })
      yearHeaders.push({ value: 'Credit Unions', colSpan: 2 })
      //Headers
      let headers = [
        'FI Asset Group',
        'Competitive Churn Rate',
        'Consolidation Churn Rate',
        'Competitive Churn Rate',
        'Consolidation Churn Rate'
      ]

      downloadReportRows.push(headers)
      const combinedData = [...this.reportTableData, this.reportTotal]
      //Report data
      combinedData.map(row => {
        let data = []
        data.push(row['name'])
        data.push({
          value: row['bank_competitive_churn_rate'],
          format: this.dataFormat['bank_competitive_churn_rate']
        })
        data.push({
          value: row['bank_consolidation_churn_rate'],
          format: this.dataFormat['bank_consolidation_churn_rate']
        })
        data.push({
          value: row['cu_competitive_churn_rate'],
          format: this.dataFormat['cu_competitive_churn_rate']
        })
        data.push({
          value: row['cu_consolidation_churn_rate'],
          format: this.dataFormat['cu_consolidation_churn_rate']
        })

        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name: 'Retail Mobile Banking Institution Analytics - Churn Rate Type',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
