<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="reportTableData.length">
          <tr>
            <th @click="sortByColumn('name')" width="15%">
              Asset Group
              <span>
                <i
                  :class="['fa fa-regular sort-icon', getSortIconClass('name')]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('market_count')"
              class="text-right"
              width="15%"
            >
              Market Count
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('market_count')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('customer_to_accounts')"
              class="text-right"
              width="15%"
            >
              {{ enrollmentText }}
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('customer_to_accounts')
                  ]"
                >
                </i>
              </span>
            </th>
            <th class="text-right" width="55%">
              {{ enrollmentText }}
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="reportTableData.length">
          <tr v-for="(data, asset_group) in reportTableData" :key="asset_group">
            <td class="name-column font-weight-bold">
              {{ data.name }}
            </td>
            <td class="text-right">
              {{ data.market_count | formatValue(dataFormat.market_count) }}
            </td>
            <td class="text-right">
              {{
                data.customer_to_accounts
                  | formatValue(dataFormat.customer_to_accounts)
              }}
            </td>

            <td class="p-0" :rowspan="reportData.length">
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-olbiae"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td>Grand Total</td>
            <td class="text-right">
              {{
                reportTotal.market_count | formatValue(dataFormat.market_count)
              }}
            </td>
            <td class="text-right">
              {{
                reportTotal.customer_to_accounts
                  | formatValue(dataFormat.customer_to_accounts)
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
// global
import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'OlbIAEnrollment',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        name: ['none', 'asset_group'],
        market_count: ['none', 'float'],
        customer_to_accounts: ['none', 'float']
      }
    }
  },
  computed: {
    ...mapState('DigitalChannels', ['fiType']),
    ...mapGetters('DigitalChannels', ['reportSubTitle']),
    reportTableData() {
      return this.reportData.filter(data => data.name !== 'Grand Total')
    },

    reportTotal() {
      return this.reportData.find(data => data.name === 'Grand Total')
    },
    enrollmentText() {
      if (this.fiType === 'Credit Unions')
        return 'OLB Enrolled Customers To Members'
      else return 'OLB Enrolled Customers To Accounts'
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-olbiae'
        ) {
          chart.destroy()
        }
      })
      const dataFormat = this.dataFormat
      Highcharts.chart('report-chart-olbiae', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(
                this.value,
                dataFormat.customer_to_accounts
              )
            }
          },
          title: {
            text: null
          }
        },
        series: [
          {
            name: '',
            data: this.reportTableData.map(rd =>
              parseFloat(rd.customer_to_accounts)
            ),
            color: '#A7CA77',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = ['Asset Group', 'Market Count', this.enrollmentText]

      downloadReportRows.push(headers)
      const combinedData = [...this.reportTableData, this.reportTotal]
      //Report data
      combinedData.map(row => {
        let data = []
        data.push(row['name'])
        data.push({
          value: row['market_count'],
          format: this.dataFormat.market_count
        })
        data.push({
          value: row['customer_to_accounts'],
          format: this.dataFormat.customer_to_accounts
        })

        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name: 'Retail Online Banking Institution Analytics - Enrollment',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
