<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="reportTableData.length">
          <tr>
            <th @click="sortByColumn('feature_segment')" width="10%">
              Feature Segment
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('feature_segment')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn(getKeyByValue(header))"
              width="7%"
              class="text-right"
              v-for="(header, periodIndex) in reportHeaders"
              :key="'subHead_' + periodIndex"
            >
              {{ header }}
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass(getKeyByValue(header))
                  ]"
                >
                </i>
              </span>
            </th>
            <th width="40%">App Rating</th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="reportTableData.length">
          <tr v-for="(data, rowIndex) in reportTableData" :key="rowIndex">
            <td class="name-column font-weight-bold">
              {{ data.feature_segment }}
            </td>
            <td
              class="text-right"
              v-for="(header, index) in Object.keys(reportHeaders)"
              :key="'data_' + rowIndex + '_' + index"
            >
              {{ data[header] | formatValue(dataFormat[header]) }}
            </td>

            <td class="p-0" :rowspan="reportData.length">
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-iafssa"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td class="name-column font-weight-bold">
              Grand Total
            </td>
            <td
              class="text-right"
              v-for="(header, index) in Object.keys(reportHeaders)"
              :key="'data_' + index"
            >
              {{ reportTotal[header] | formatValue(dataFormat[header]) }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
// global
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'IAFeatureSegSatAssets',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    },
    reportHeaders: {
      required: true,
      default: []
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        feature_segment: ['none', 'feature_segment']
      }
    }
  },
  created() {
    Object.keys(this.reportHeaders).forEach(key => {
      this.sortOrder[key] = ['none', 'float']
    })
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  computed: {
    ...mapGetters('DigitalChannels', ['reportSubTitle']),
    reportTableData() {
      return this.reportData.filter(
        data => data.feature_segment !== 'Grand Total'
      )
    },

    reportTotal() {
      return this.reportData.find(
        data => data.feature_segment === 'Grand Total'
      )
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    getKeyByValue(value) {
      const obj = this.reportHeaders
      return Object.keys(obj).find(key => obj[key] === value)
    },
    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-iafssa'
        ) {
          chart.destroy()
        }
      })
      const dataFormat = this.dataFormat
      Highcharts.chart('report-chart-iafssa', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(this.value, dataFormat['total'])
            }
          },
          title: {
            text: null
          }
        },
        series: [
          {
            data: this.reportTableData.map(rd => parseFloat(rd.total)),
            color: '#a7ca77',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = ['Feature Segment', ...Object.values(this.reportHeaders)]

      downloadReportRows.push(headers)
      const combinedData = [...this.reportTableData, this.reportTotal]
      //Report data
      combinedData.map(row => {
        let data = []
        data.push(row['feature_segment'])
        Object.keys(this.reportHeaders).map(header => {
          data.push({ value: row[header], format: this.dataFormat[header] })
        })

        downloadReportRows.push(data)
      })
      this.setDownloadDCReportData({
        name:
          'Retail Mobile Banking Institution Analytics - Feature Segment Satisfaction Assets',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
