<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="regulatedTableData.length">
          <tr>
            <th width="4%">Rank</th>
            <th
              @click="sortByColumn('vendor_name')"
              :width="canShowProducts ? '21%' : '30%'"
            >
              Vendor Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('vendor_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('product_name')"
              width="15%"
              v-if="canShowProducts"
            >
              Product Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('product_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('previous_market_count')"
              class="text-right"
              :width="canShowProducts ? '10%' : '11%'"
            >
              Previous Market Count
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('previous_market_count')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('previous_market_share')"
              class="text-right"
              :width="canShowProducts ? '10%' : '11%'"
            >
              Previous Market Share
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('previous_market_share')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('current_market_count')"
              class="text-right"
              :width="canShowProducts ? '10%' : '11%'"
            >
              Current Market Count
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('current_market_count')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('current_market_share')"
              class="text-right"
              :width="canShowProducts ? '10%' : '11%'"
            >
              Current Market Share
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('current_market_share')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('net_market_growth')"
              class="text-right"
              :width="canShowProducts ? '10%' : '11%'"
            >
              Net Market Growth
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('net_market_growth')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('market_share_accretion')"
              class="text-right"
              :width="canShowProducts ? '10%' : '11%'"
            >
              Market Share Accretion
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('market_share_accretion')
                  ]"
                >
                </i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="regulatedTableData.length">
          <tr v-for="(data, rank) in regulatedTableData" :key="rank">
            <td class="">
              {{ rank + 1 }}
            </td>
            <td class="name-column" :title="data.vendor_name">
              {{ data.vendor_name }}
            </td>
            <td
              class="name-column"
              :title="data.product_name"
              v-if="canShowProducts"
            >
              {{ data.product_name }}
            </td>
            <td class="text-right">
              {{ parseFloat(data.previous_market_count) | numberFormat }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.previous_market_share)
                  | formatValue(dataFormat.previous_market_share)
              }}
            </td>
            <td class="text-right">
              {{ parseFloat(data.current_market_count) | numberFormat }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.current_market_share)
                  | formatValue(dataFormat.current_market_share)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.net_market_growth)
                  | formatValue(dataFormat.net_market_growth)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.market_share_accretion)
                  | formatValue(dataFormat.market_share_accretion)
              }}
            </td>
          </tr>
          <tr class="report-footer">
            <td :colspan="canShowProducts ? '3' : '2'">Grand Total</td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.previous_market_count)
                  | formatValue(dataFormat.previous_market_count)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.previous_market_share)
                  | formatValue(dataFormat.previous_market_share)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.current_market_count)
                  | formatValue(dataFormat.current_market_count)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.current_market_share)
                  | formatValue(dataFormat.current_market_share)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.net_market_growth)
                  | formatValue(dataFormat.net_market_growth)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.market_share_accretion)
                  | formatValue(dataFormat.market_share_accretion)
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="canShowProducts ? 9 : 8">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// global
import { mapState, mapGetters, mapMutations } from 'vuex'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'VAMarketShareAccretion',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    },
    reportOtherData: {
      required: true,
      default: null
    },
    reportTotal: {
      required: true,
      default: () => {
        return {}
      }
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  updated() {
    this.prepareDownloadReport()
  },
  data() {
    return {
      sortOrder: {
        vendor_name: ['none', 'string'],
        product_name: ['none', 'string'],
        previous_market_count: ['none', 'float'],
        previous_market_share: ['none', 'float'],
        current_market_count: ['none', 'float'],
        current_market_share: ['none', 'float'],
        net_market_growth: ['none', 'float'],
        market_share_accretion: ['none', 'float']
      }
    }
  },
  computed: {
    ...mapState('DigitalChannels', ['topVendors', 'topVendorsMax']),
    ...mapGetters('DigitalChannels', ['canShowProducts', 'reportSubTitle']),
    regulatedTableData() {
      let regulatedData = this.reportData ? [...this.reportData] : []
      if (this.reportOtherData && this.reportData.length < this.topVendorsMax) {
        regulatedData.push(this.reportOtherData)
      }

      return regulatedData
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    drawChart() {
      // chart not needed for this report
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'Rank',
        'Vendor Name',
        { value: 'Previous Market Count', format: { alignment: 'right' } },
        { value: 'Previous Market Share', format: { alignment: 'right' } },
        { value: 'Current Market Count', format: { alignment: 'right' } },
        { value: 'Current Market Share', format: { alignment: 'right' } },
        { value: 'Net Market Growth', format: { alignment: 'right' } },
        { value: 'Market Share Accretion', format: { alignment: 'right' } }
      ]
      if (this.canShowProducts) headers.splice(2, 0, 'Product Name')
      downloadReportRows.push(headers)
      const combinedData = [...this.regulatedTableData, this.reportTotal]
      //Report data
      combinedData.map((row, index) => {
        let data = []
        data.push(index === combinedData.length - 1 ? '' : index + 1)
        data.push(row['vendor_name'])
        if (this.canShowProducts) {
          data.push(row['product_name'] ? row['product_name'] : '')
        }
        data.push({
          value: row['previous_market_count'],
          format: '#,###'
        })
        data.push({
          value: row['previous_market_share'],
          format: this.dataFormat['previous_market_share']
        })
        data.push({
          value: row['current_market_count'],
          format: '#,###'
        })
        data.push({
          value: row['current_market_share'],
          format: this.dataFormat['current_market_share']
        })
        data.push({
          value: row['net_market_growth'],
          format: this.dataFormat['net_market_growth']
        })
        data.push({
          value: row['market_share_accretion'],
          format: this.dataFormat['market_share_accretion']
        })
        downloadReportRows.push(data)
      })
      this.setDownloadDCReportData({
        name: 'Vendor Analytics - Market Share Accretion',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
