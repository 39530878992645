var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-gutters market-report-container"},[_c('div',{},[_c('table',{staticClass:"table table-striped"},[(_vm.reportTableData.length)?_c('thead',[_c('tr',[_c('th',{attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('name')}}},[_vm._v(" FI Asset Group "),_c('span',[_c('i',{class:['fa fa-regular sort-icon', _vm.getSortIconClass('name')]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"10%"},on:{"click":function($event){return _vm.sortByColumn('total_fi')}}},[_vm._v(" FI Count "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('total_fi')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('fi_w_app')}}},[_vm._v(" FIs w/ App "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('fi_w_app')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('fi_w_app_pct')}}},[_vm._v(" FIs w/ App % "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('fi_w_app_pct')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('avg_mb_age')}}},[_vm._v(" Avg MB Age "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('avg_mb_age')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"30%"}},[_vm._v(" FI Adoption % ")])])]):_vm._e(),(_vm.reportTableData.length)?_c('tbody',{ref:"reportTableBody"},[_vm._l((_vm.reportTableData),function(data,asset_group){return _c('tr',{key:asset_group},[_c('td',{staticClass:"name-column font-weight-bold"},[_vm._v(" "+_vm._s(data.name)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.total_fi,_vm.dataFormat.total_fi))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.fi_w_app,_vm.dataFormat.fi_w_app))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.fi_w_app_pct,_vm.dataFormat.fi_w_app_pct))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.avg_mb_age,_vm.dataFormat.avg_mb_age))+" ")]),_c('td',{staticClass:"p-0",attrs:{"rowspan":_vm.reportData.length}},[(_vm.showChart)?_c('div',{ref:"reportChartContainer",refInFor:true,staticClass:"report-chart-container"},[_c('div',{attrs:{"id":"report-chart-iaa"}})]):_vm._e()])])}),_c('tr',{staticClass:"report-footer"},[_c('td',[_vm._v("Grand Total")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.total_fi,_vm.dataFormat.total_fi))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.fi_w_app,_vm.dataFormat.fi_w_app))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.fi_w_app_pct,_vm.dataFormat.fi_w_app_pct))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.avg_mb_age,_vm.dataFormat.avg_mb_age))+" ")])])],2):_c('tbody',[_vm._m(0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('div',{staticClass:"text-center text-muted"},[_vm._v("No Records found")])])])}]

export { render, staticRenderFns }