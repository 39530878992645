<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="reportTableData.length">
          <tr>
            <th width="15%">Name</th>

            <th class="text-right" width="5%">Yes/No</th>
            <th
              @click="sortByColumn('has_feature_current')"
              class="text-right"
              width="10%"
            >
              FI Count
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('has_feature_current')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('ratings_to_installs')"
              class="text-right"
              width="10%"
            >
              Ratings To Installs
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('ratings_to_installs')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('app_average_rating')"
              class="text-right"
              width="10%"
            >
              App Average Rating
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('app_average_rating')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('satisfaction_feature_lift')"
              class="text-right"
              width="12%"
            >
              Feature Lift
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('satisfaction_feature_lift')
                  ]"
                >
                </i>
              </span>
            </th>
            <th class="text-right" width="38%">
              App Average Rating
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="reportTableData.length">
          <tr v-for="(data, index) in reportTableData" :key="index">
            <td class="name-column font-weight-bold">
              {{ getName(index) }}
            </td>
            <td class="text-right">
              {{ data.has_feature }}
            </td>
            <td class="text-right">
              {{
                data.has_feature_current
                  | formatValue(dataFormat.has_feature_current)
              }}
            </td>
            <td class="text-right">
              {{
                data.ratings_to_installs
                  | formatValue(dataFormat.ratings_to_installs)
              }}
            </td>
            <td class="text-right">
              {{
                data.app_average_rating
                  | formatValue(dataFormat.app_average_rating)
              }}
            </td>
            <td class="text-right">
              {{
                data.satisfaction_feature_lift
                  | formatValue(dataFormat.satisfaction_feature_lift)
              }}
            </td>

            <td class="p-0" :rowspan="reportData.length">
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-iafsl" ref="reportChart"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td class="name-column font-weight-bold" colspan="2">
              Grand Total
            </td>
            <td class="text-right">
              {{
                reportTotal.has_feature_current
                  | formatValue(dataFormat.has_feature_current)
              }}
            </td>
            <td class="text-right">
              {{
                reportTotal.ratings_to_installs
                  | formatValue(dataFormat.ratings_to_installs)
              }}
            </td>
            <td class="text-right">
              {{
                reportTotal.app_average_rating
                  | formatValue(dataFormat.app_average_rating)
              }}
            </td>
            <td class="text-right">
              {{
                reportTotal.satisfaction_feature_lift
                  | formatValue(dataFormat.satisfaction_feature_lift)
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
// global
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'IAFeatureSatLift',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        has_feature_current: ['none', 'float'],
        ratings_to_installs: ['none', 'float'],
        app_average_rating: ['none', 'float'],
        satisfaction_feature_lift: ['none', 'float']
      }
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  computed: {
    ...mapGetters('DigitalChannels', ['reportSubTitle']),
    reportTableData() {
      return this.reportData.filter(data => data.name !== 'Grand Total')
    },

    reportTotal() {
      return this.reportData.find(data => data.name === 'Grand Total')
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    getName(current) {
      if (current - 1 < 0) return this.reportTableData[current].name
      else if (
        !this.reportTableData[current] ||
        this.reportTableData[current].name ===
          this.reportTableData[current - 1].name
      )
        return ''
      else return this.reportTableData[current].name
    },
    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-iafsl'
        ) {
          chart.destroy()
        }
      })
      const dataFormat = this.dataFormat
      Highcharts.chart('report-chart-iafsl', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(
                this.value,
                dataFormat.app_average_rating
              )
            }
          },
          title: {
            text: null
          }
        },
        series: [
          {
            data: this.reportTableData.map(rd =>
              parseFloat(rd.app_average_rating)
            ),
            color: '#A7CA77',
            negativeColor: '#333333',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'Name',
        'Yes/No',
        'FI Count',
        'Ratings To Installs',
        'App Average Rating',
        'Feature Lift'
      ]

      downloadReportRows.push(headers)
      const combinedData = [...this.reportTableData, this.reportTotal]
      const name = this.getName
      //Report data
      combinedData.map((row, index) => {
        let data = []
        data.push(name(index))
        data.push(row['has_feature'] ? row['has_feature'] : 'Grand Total')
        data.push({
          value: row['has_feature_current'],
          format: this.dataFormat['has_feature_current']
        })
        data.push({
          value: row['ratings_to_installs'],
          format: this.dataFormat['ratings_to_installs']
        })
        data.push({
          value: row['app_average_rating'],
          format: this.dataFormat['app_average_rating']
        })
        data.push({
          value: row['satisfaction_feature_lift'],
          format: this.dataFormat['satisfaction_feature_lift']
        })
        downloadReportRows.push(data)
      })
      this.setDownloadDCReportData({
        name:
          'Retail Mobile Banking Institution Analytics - Feature Satisfaction Lift',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
