<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table
        :class="[
          'table',
          'table-striped',
          canShowProducts ? 'small-font-for-print' : ''
        ]"
      >
        <thead v-if="regulatedTableData.length">
          <tr>
            <th width="4%">Rank</th>
            <th
              @click="sortByColumn('vendor_name')"
              :width="canShowProducts ? '15%' : '26%'"
            >
              Vendor Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('vendor_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('product_name')"
              width="15%"
              v-if="canShowProducts"
            >
              Product Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('product_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('customer_enrollment_2yr_ago')"
              class="text-right"
              :width="canShowProducts ? '9%' : '10%'"
            >
              Customer Enrollment - 2 Yrs Ago
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('customer_enrollment_2yr_ago')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('customer_enrollment_1yr_ago')"
              class="text-right"
              :width="canShowProducts ? '9%' : '10%'"
            >
              Customer Enrollment - 1 Yr Ago
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('customer_enrollment_1yr_ago')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('customer_enrollment_current')"
              class="text-right"
              :width="canShowProducts ? '9%' : '10%'"
            >
              Customer Enrollment - Current
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('customer_enrollment_current')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('enrollment_change')"
              class="text-right"
              :width="canShowProducts ? '9%' : '10%'"
            >
              2 Year Change
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('enrollment_change')
                  ]"
                >
                </i>
              </span>
            </th>
            <th class="text-right" width="30%">
              Enrolled Customers to Accounts - 2 Yr Change
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="regulatedTableData.length">
          <tr v-for="(data, rank) in regulatedTableData" :key="rank">
            <td class="">
              {{ rank + 1 }}
            </td>
            <td class="name-column" :title="data.vendor_name">
              {{ data.vendor_name }}
            </td>
            <td
              class="name-column"
              :title="data.product_name"
              v-if="canShowProducts"
            >
              {{ data.product_name }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.customer_enrollment_2yr_ago)
                  | formatValue(dataFormat.customer_enrollment_2yr_ago)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.customer_enrollment_1yr_ago)
                  | formatValue(dataFormat.customer_enrollment_1yr_ago)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.customer_enrollment_current)
                  | formatValue(dataFormat.customer_enrollment_current)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.enrollment_change)
                  | formatValue(dataFormat.enrollment_change)
              }}
            </td>
            <td
              class="p-0"
              :rowspan="regulatedTableData.length + 1"
              v-if="rank === 0"
            >
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-vaeg"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td :colspan="canShowProducts ? '3' : '2'">Grand Total</td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.customer_enrollment_2yr_ago)
                  | formatValue(dataFormat.customer_enrollment_2yr_ago)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.customer_enrollment_1yr_ago)
                  | formatValue(dataFormat.customer_enrollment_1yr_ago)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.customer_enrollment_current)
                  | formatValue(dataFormat.customer_enrollment_current)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.enrollment_change)
                  | formatValue(dataFormat.enrollment_change)
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="canShowProducts ? 8 : 7">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'VAEnrollmentGrowth',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    },
    reportOtherData: {
      required: true,
      default: null
    },
    reportTotal: {
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        vendor_name: ['none', 'string'],
        product_name: ['none', 'string'],
        customer_enrollment_2yr_ago: ['none', 'float'],
        customer_enrollment_1yr_ago: ['none', 'float'],
        customer_enrollment_current: ['none', 'float'],
        enrollment_change: ['none', 'float']
      }
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  computed: {
    ...mapState('DigitalChannels', ['topVendors', 'topVendorsMax']),
    ...mapGetters('DigitalChannels', ['canShowProducts', 'reportSubTitle']),

    regulatedTableData() {
      let regulatedData = this.reportData ? [...this.reportData] : []

      if (this.reportOtherData && this.reportData.length < this.topVendorsMax) {
        regulatedData.push(this.reportOtherData)
      }

      return regulatedData
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),

    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-vaeg'
        ) {
          chart.destroy()
        }
      })
      const dataFormat = this.dataFormat

      Highcharts.chart('report-chart-vaeg', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(
                this.value,
                dataFormat.enrollment_change
              )
            }
          }
        },
        series: [
          {
            name: '',
            data: this.regulatedTableData.map(rd =>
              parseFloat(rd.enrollment_change)
            ),
            color: '#A7CA77',
            negativeColor: '#333333',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'Rank',
        'Vendor Name',
        {
          value: 'Customer Enrollment - 2 Yrs Ago',
          format: { alignment: 'right' }
        },
        {
          value: 'Customer Enrollment - 1 Yr Ago',
          format: { alignment: 'right' }
        },
        {
          value: 'Customer Enrollment - Current',
          format: { alignment: 'right' }
        },
        {
          value: 'Enrolled Customers to Accounts - 2 Yr Change',
          format: { alignment: 'right' }
        }
      ]
      if (this.canShowProducts) headers.splice(2, 0, 'Product Name')
      downloadReportRows.push(headers)
      const combinedData = [...this.regulatedTableData, this.reportTotal]
      //Report data
      combinedData.map((row, index) => {
        let data = []
        data.push(index === combinedData.length - 1 ? '' : index + 1)
        data.push(row['vendor_name'])
        if (this.canShowProducts) {
          data.push(row['product_name'] ? row['product_name'] : '')
        }
        data.push({
          value: row['customer_enrollment_2yr_ago'],
          format: this.dataFormat['customer_enrollment_2yr_ago']
        })
        data.push({
          value: row['customer_enrollment_1yr_ago'],
          format: this.dataFormat['customer_enrollment_1yr_ago']
        })
        data.push({
          value: row['customer_enrollment_current'],
          format: this.dataFormat['customer_enrollment_current']
        })
        data.push({
          value: row['enrollment_change'],
          format: this.dataFormat['enrollment_change']
        })
        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name: 'Retail Mobile Banking Vendor Analytics - Enrollment Growth',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
