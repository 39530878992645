<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="reportTableData.length">
          <tr>
            <th @click="sortByColumn('name')" width="10%">
              FI Asset Group
              <span>
                <i
                  :class="['fa fa-regular sort-icon', getSortIconClass('name')]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('previous_market_measure')"
              class="text-right"
              width="10%"
            >
              Previous Market Measure
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('previous_market_measure')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('market_lost')"
              class="text-right"
              width="10%"
            >
              Market Lost
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('market_lost')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('competitive_churn_rate')"
              class="text-right"
              width="10%"
            >
              Competitive Churn Rate
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('competitive_churn_rate')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('consolidation_churn_rate')"
              class="text-right"
              width="10%"
            >
              Consolidation Churn Rate
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('consolidation_churn_rate')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('total_churn_rate')"
              class="text-right"
              width="10%"
            >
              Total Churn Rate
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('total_churn_rate')
                  ]"
                >
                </i>
              </span>
            </th>
            <th class="text-right" width="40%">
              <div class="">
                <div class="text-left">
                  <span class="bar-legend lifetime-installs"></span>
                  <span class="px-2">Competitive Churn Rate</span>
                </div>
                <div class="text-left">
                  <span class="bar-legend enrollment "></span>
                  <span class="px-2">Consolidation Churn Rate</span>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="reportTableData.length">
          <tr v-for="(data, asset_group) in reportTableData" :key="asset_group">
            <td class="name-column font-weight-bold">
              {{ data.name }}
            </td>
            <td class="text-right">
              {{
                data.previous_market_measure
                  | formatValue(dataFormat.previous_market_measure || '#,###')
              }}
            </td>
            <td class="text-right">
              {{
                data.market_lost
                  | formatValue(dataFormat.market_lost || '#,###')
              }}
            </td>
            <td class="text-right">
              {{
                data.competitive_churn_rate
                  | formatValue(dataFormat.competitive_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                data.consolidation_churn_rate
                  | formatValue(dataFormat.consolidation_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                data.total_churn_rate | formatValue(dataFormat.total_churn_rate)
              }}
            </td>
            <td class="p-0" :rowspan="reportData.length">
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-iacr"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td>Grand Total</td>
            <td class="text-right">
              {{
                reportTotal.previous_market_measure
                  | formatValue(dataFormat.previous_market_measure || '#,###')
              }}
            </td>
            <td class="text-right">
              {{
                reportTotal.market_lost
                  | formatValue(dataFormat.market_lost || '#,###')
              }}
            </td>
            <td class="text-right">
              {{
                reportTotal.competitive_churn_rate
                  | formatValue(dataFormat.competitive_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                reportTotal.consolidation_churn_rate
                  | formatValue(dataFormat.consolidation_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                reportTotal.total_churn_rate
                  | formatValue(dataFormat.total_churn_rate)
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
// global
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'IAChurnRate',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        name: ['none', 'asset_group'],
        previous_market_measure: ['none', 'float'],
        market_lost: ['none', 'float'],
        competitive_churn_rate: ['none', 'float'],
        consolidation_churn_rate: ['none', 'float'],
        total_churn_rate: ['none', 'float']
      }
    }
  },
  computed: {
    ...mapGetters('DigitalChannels', ['reportSubTitle']),
    reportTableData() {
      return this.reportData.filter(data => data.name !== 'Grand Total')
    },

    reportTotal() {
      return this.reportData.find(data => data.name === 'Grand Total')
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-iacr'
        ) {
          chart.destroy()
        }
      })
      const dataFormat = this.dataFormat
      Highcharts.chart('report-chart-iacr', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0,
            stacking: 'normal'
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(
                this.value,
                dataFormat.consolidation_churn_rate
              )
            }
          }
        },
        series: [
          {
            data: this.reportTableData.map(rd =>
              parseFloat(rd.consolidation_churn_rate)
            ),
            color: '#333333',
            pointPlacement: this.pointPlacementValue()
          },
          {
            data: this.reportTableData.map(rd =>
              parseFloat(rd.competitive_churn_rate)
            ),
            color: '#A7CA77',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'FI Asset Group',
        'Previous Market Measure',
        'Market Lost',
        'Competitive Churn Rate',
        'Consolidation Churn Rate',
        'Total Churn Rate'
      ]

      downloadReportRows.push(headers)
      const combinedData = [...this.reportTableData, this.reportTotal]
      //Report data
      combinedData.map(row => {
        let data = []
        data.push(row['name'])
        data.push({
          value: row['previous_market_measure'],
          format: '#,###'
        })
        data.push({
          value: row['market_lost'],
          format: '#,###'
        })
        data.push({
          value: row['competitive_churn_rate'],
          format: this.dataFormat['competitive_churn_rate']
        })
        data.push({
          value: row['consolidation_churn_rate'],
          format: this.dataFormat['consolidation_churn_rate']
        })
        data.push({
          value: row['total_churn_rate'],
          format: this.dataFormat['total_churn_rate']
        })
        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name: 'Retail Mobile Banking Institution Analytics - Churn Rate',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
