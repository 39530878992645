<template>
  <div class="spline-chart-container">
    <Highcharts :options="chartOptions" ref="splineChart"></Highcharts>
  </div>
</template>
<script>
import Vue from 'vue'
// ui components
import { Chart } from 'highcharts-vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SplineChart',
  props: {
    headers: {
      type: Object,
      required: true
    },
    chartData: {
      type: Object,
      required: true
    },
    chartIndex: {
      type: Number,
      required: true
    },
    dataFormat: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  components: {
    Highcharts: Chart
  },
  mounted() {
    // this.reflowChart()
  },
  computed: {
    ...mapState({
      isSidebarExpanded: state => state.isSidebarExpanded
    }),
    ...mapGetters(['isAsideOpen']),
    chartRef() {
      return `lineChart-${this.chartIndex}`
    },
    formattedChartData() {
      return Object.keys(this.headers).map(k =>
        Vue.filter('formatValue')(
          parseFloat(this.chartData[k]),
          this.dataFormat.format
        )
      )
    },
    chartOptions() {
      return {
        chart: {
          type: 'spline',
          height: 50,
          backgroundColor: this.chartIndex % 2 ? '#ffffff' : '#f4f4f4'
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false
          }
        },
        xAxis: {
          categories: this.headers,
          gridLineWidth: 1,
          lineWidth: 0,
          tickLength: 0,
          startOnTick: true,
          endOnTick: true,
          labels: {
            enabled: false
          },
          title: {
            text: undefined
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        series: [
          {
            data: this.formattedChartData.map(d => parseFloat(d)),
            color: '#A7CA77'
          }
        ]
      }
    }
  },
  methods: {
    reflowChart() {
      setTimeout(
        function() {
          let chart = this.$refs.splineChart && this.$refs.splineChart.chart
          chart.reflow()
        }.bind(this),
        500
      )
    }
  },
  watch: {
    isSidebarExpanded: function() {
      this.reflowChart()
    },
    isAsideOpen: function() {
      this.reflowChart()
    }
  }
}
</script>

<style lang="scss" scoped>
.digital-channels-report {
  .spline-chart-container {
    height: 100%;
    width: 500px;
  }
}
</style>
