<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped small-font-for-print">
        <thead v-if="regulatedTableData.length">
          <tr>
            <th @click="sortByColumn('vendor_name')" width="16%">
              Vendor Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('vendor_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('previous_market_measure')"
              class="text-right"
              width="12%"
            >
              Previous Market Measure
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('previous_market_measure')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('market_held')"
              class="text-right"
              width="12%"
            >
              Market Held
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('market_held')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('won_orgination')"
              class="text-right"
              width="12%"
            >
              Won Origination
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('won_orgination')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('won_conversion')"
              class="text-right"
              width="12%"
            >
              Won Conversion
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('won_conversion')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('lost_conversion')"
              class="text-right"
              width="12%"
            >
              Lost Conversion
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('lost_conversion')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('lost_consolidation')"
              class="text-right"
              width="12%"
            >
              Lost Consolidation
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('lost_consolidation')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('current_market_measure')"
              class="text-right"
              width="12%"
            >
              Current Market Measure
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('current_market_measure')
                  ]"
                >
                </i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="regulatedTableData.length">
          <tr v-for="(data, rank) in regulatedTableData" :key="rank">
            <td class="name-column" :title="data.vendor_name">
              {{ data.vendor_name }}
            </td>
            <td class="text-right">
              {{ data.previous_market_measure | formatValue('#,###') }}
            </td>
            <td class="text-right">
              {{ data.market_held | formatValue('#,###') }}
            </td>
            <td class="text-right">
              {{ data.won_orgination | formatValue('#,###') }}
            </td>
            <td class="text-right">
              {{ data.won_conversion | formatValue('#,###') }}
            </td>
            <td class="text-right">
              {{ data.lost_conversion | formatValue('#,###') }}
            </td>
            <td class="text-right">
              {{ data.lost_consolidation | formatValue('#,###') }}
            </td>
            <td class="text-right">
              {{ data.current_market_measure | formatValue('#,###') }}
            </td>
          </tr>
          <tr class="report-footer">
            <td>Grand Total</td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.previous_market_measure)
                  | formatValue(dataFormat.previous_market_measure | '#,###')
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.market_held)
                  | formatValue(dataFormat.market_held || '#,###')
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.won_orgination)
                  | formatValue(dataFormat.won_orgination || '#,###')
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.won_conversion)
                  | formatValue(dataFormat.won_conversion || '#,###')
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.lost_conversion)
                  | formatValue(dataFormat.lost_conversion || '#,###')
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.lost_consolidation)
                  | formatValue(dataFormat.lost_consolidation || '#,###')
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.current_market_measure)
                  | formatValue(dataFormat.current_market_measure || '#,###')
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
// global
import { mapState, mapGetters, mapMutations } from 'vuex'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'VAWinLossImpact',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    },
    reportOtherData: {
      required: true,
      default: null
    },
    reportTotal: {
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      sortOrder: {
        vendor_name: ['none', 'string'],
        previous_market_measure: ['none', 'float'],
        market_held: ['none', 'float'],
        won_orgination: ['none', 'float'],
        won_conversion: ['none', 'float'],
        lost_conversion: ['none', 'float'],
        lost_consolidation: ['none', 'float'],
        current_market_measure: ['none', 'float']
      }
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  computed: {
    ...mapState('DigitalChannels', ['topVendors', 'topVendorsMax']),
    ...mapGetters('DigitalChannels', ['canShowProducts', 'reportSubTitle']),
    regulatedTableData() {
      let regulatedData = this.reportData ? [...this.reportData] : []

      if (this.reportOtherData && this.reportData.length < this.topVendorsMax) {
        regulatedData.push(this.reportOtherData)
      }

      return regulatedData
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'Vendor Name',
        { value: 'Previous Market Measure', format: { alignment: 'right' } },
        { value: 'Market Held', format: { alignment: 'right' } },
        { value: 'Won Origination', format: { alignment: 'right' } },
        { value: 'Won Conversion', format: { alignment: 'right' } },
        { value: 'Lost Conversion', format: { alignment: 'right' } },
        { value: 'Lost Consolidation', format: { alignment: 'right' } },
        { value: 'Current Market Measure', format: { alignment: 'right' } }
      ]

      downloadReportRows.push(headers)
      const combinedData = [...this.regulatedTableData, this.reportTotal]
      //Report data
      combinedData.map(row => {
        let data = []

        data.push(row['vendor_name'])

        data.push({
          value: row['previous_market_measure'],
          format: '#,###'
        })
        data.push({
          value: row['market_held'],
          format: '#,###'
        })
        data.push({
          value: row['won_orgination'],
          format: '#,###'
        })
        data.push({
          value: row['won_conversion'],
          format: '#,###'
        })
        data.push({
          value: row['lost_conversion'],
          format: '#,###'
        })
        data.push({
          value: row['lost_consolidation'],
          format: '#,###'
        })
        data.push({
          value: row['current_market_measure'],
          format: '#,###'
        })
        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name: 'Retail Mobile Banking Vendor Analytics - Win Loss Impact',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
