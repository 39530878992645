<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table
        :class="[
          'table',
          'table-striped',
          canShowProducts && canShowBankDetails && canShowCuDetails
            ? 'small-font-for-print'
            : ''
        ]"
      >
        <thead v-if="regulatedTableData.length">
          <tr>
            <td :colspan="canShowProducts ? 2 : 1" width="10%"></td>
            <td
              colspan="2"
              class="left-border text-center no-bottom-padding"
              v-if="canShowBankDetails"
              width="20%"
            >
              <b>Banks</b>
            </td>
            <td
              colspan="2"
              class="left-border text-center no-bottom-padding "
              v-if="canShowCuDetails"
              width="20%"
            >
              <b>Credit Unions</b>
            </td>
            <td
              colspan="2"
              class="left-border text-center no-bottom-padding right-border"
              width="20%"
            >
              <b>Grand Total</b>
            </td>
            <td width="30%"></td>
          </tr>
          <tr>
            <th @click="sortByColumn('vendor_name')" width="10%">
              OLB Vendor
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('vendor_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('olb_product_name')"
              width="10%"
              v-if="canShowProducts"
            >
              Product Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('olb_product_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('market_measure_bank')"
              class="text-right left-border "
              :width="canShowProducts ? '5%' : '10%'"
              v-if="canShowBankDetails"
            >
              Market Measure
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('market_measure_bank')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('client_avg_bank')"
              class="text-right"
              width="10%"
              v-if="canShowBankDetails"
            >
              FI Client Average
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('client_avg_bank')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('market_measure_cu')"
              class="text-right left-border "
              :width="canShowProducts ? '5%' : '10%'"
              v-if="canShowCuDetails"
            >
              Market Measure
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('market_measure_cu')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('client_avg_cu')"
              class="text-right"
              width="10%"
              v-if="canShowCuDetails"
            >
              FI Client Average
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('client_avg_cu')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('market_measure')"
              class="text-right left-border "
              :width="canShowProducts ? '5%' : '10%'"
            >
              Market Measure
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('market_measure')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('client_avg')"
              class="text-right right-border"
              width="10%"
            >
              FI Client Average
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('client_avg')
                  ]"
                >
                </i>
              </span>
            </th>
            <th class="text-right " width="30%">
              FI Client Average
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="regulatedTableData.length">
          <tr v-for="(data, rank) in regulatedTableData" :key="rank">
            <td class="name-column" :title="data.vendor">
              {{ data.vendor_name }}
            </td>
            <td
              class="name-column"
              :title="data.product_name"
              v-if="canShowProducts"
            >
              {{ data.olb_product_name }}
            </td>
            <td class="text-right left-border " v-if="canShowBankDetails">
              {{
                data.market_measure_bank
                  | formatValue(dataFormat.market_measure_bank)
              }}
            </td>
            <td class="text-right" v-if="canShowBankDetails">
              {{
                data.client_avg_bank | formatValue(dataFormat.client_avg_bank)
              }}
            </td>
            <td class="text-right left-border " v-if="canShowCuDetails">
              {{
                data.market_measure_cu
                  | formatValue(dataFormat.market_measure_cu)
              }}
            </td>
            <td class="text-right " v-if="canShowCuDetails">
              {{ data.client_avg_cu | formatValue(dataFormat.client_avg_cu) }}
            </td>
            <td class="text-right left-border ">
              {{ data.market_measure | formatValue(dataFormat.market_measure) }}
            </td>
            <td class="text-right  right-border">
              {{ data.client_avg | formatValue(dataFormat.client_avg) }}
            </td>

            <td
              class="p-0"
              :rowspan="regulatedTableData.length + 1"
              v-if="rank === 0"
            >
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-olbvacf"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td :colspan="canShowProducts ? 2 : 1">Grand Total</td>
            <td class="text-right left-border " v-if="canShowBankDetails">
              {{
                reportTotal.market_measure_bank
                  | formatValue(dataFormat.market_measure_bank)
              }}
            </td>
            <td class="text-right" v-if="canShowBankDetails">
              {{
                reportTotal.client_avg_bank
                  | formatValue(dataFormat.client_avg_bank)
              }}
            </td>
            <td class="text-right left-border " v-if="canShowCuDetails">
              {{
                reportTotal.market_measure_cu
                  | formatValue(dataFormat.market_measure_cu)
              }}
            </td>
            <td class="text-right" v-if="canShowCuDetails">
              {{
                reportTotal.client_avg_cu
                  | formatValue(dataFormat.client_avg_cu)
              }}
            </td>
            <td class="text-right left-border ">
              {{
                reportTotal.market_measure
                  | formatValue(dataFormat.market_measure)
              }}
            </td>
            <td class="text-right right-border">
              {{ reportTotal.client_avg | formatValue(dataFormat.client_avg) }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
// global
import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'OlbVAClientFirmographics',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    },
    reportOtherData: {
      required: true,
      default: null
    },
    reportTotal: {
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        vendor_name: ['none', 'string'],
        olb_product_name: ['none', 'string'],
        market_measure_bank: ['none', 'float'],
        client_avg_bank: ['none', 'float'],
        market_measure_cu: ['none', 'float'],
        client_avg_cu: ['none', 'float'],
        market_measure: ['none', 'float'],
        client_avg: ['none', 'float']
      }
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },

  updated() {
    this.prepareDownloadReport()
  },
  computed: {
    ...mapState('DigitalChannels', ['topVendors', 'topVendorsMax', 'fiType']),
    ...mapGetters('DigitalChannels', ['canShowProducts', 'reportSubTitle']),
    regulatedTableData() {
      let regulatedData = this.reportData ? [...this.reportData] : []

      if (this.reportOtherData && this.reportData.length < this.topVendorsMax) {
        regulatedData.push(this.reportOtherData)
      }

      return regulatedData
    },
    canShowBankDetails() {
      if (this.fiType === 'Credit Unions') return false
      else return true
    },
    canShowCuDetails() {
      if (this.fiType === 'Banks') return false
      else return true
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),

    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-olbvacf'
        ) {
          chart.destroy()
        }
      })

      Highcharts.chart('report-chart-olbvacf', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0,
            stacking: 'normal'
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(this.value, '#,###K')
            }
          }
        },
        series: [
          {
            name: '',
            data: this.regulatedTableData.map(rd => parseFloat(rd.client_avg)),
            color: '#A7CA77',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },

    prepareDownloadReport() {
      let downloadReportRows = []
      //year Headers
      let yearHeaders = []
      if (this.canShowProducts) yearHeaders.push({ value: '', colSpan: 1 })
      if (this.canShowBankDetails)
        yearHeaders.push({ value: 'Banks', colSpan: 2 })
      if (this.canShowCuDetails)
        yearHeaders.push({ value: 'Credit Unions', colSpan: 2 })
      yearHeaders.push({ value: 'Grand Total', colSpan: 2 })
      //Headers
      let headers = [
        'Vendor Name',
        { value: 'Market Measure', format: { alignment: 'right' } },
        { value: 'FI Client Average', format: { alignment: 'right' } }
      ]
      if (this.canShowProducts) headers.splice(1, 0, 'Product Name')
      if (this.canShowBankDetails) {
        headers.push({
          value: 'Market Measure',
          format: { alignment: 'right' }
        })
        headers.push({
          value: 'FI Client Average',
          format: { alignment: 'right' }
        })
      }
      if (this.canShowCuDetails) {
        headers.push({
          value: 'Market Measure',
          format: { alignment: 'right' }
        })
        headers.push({
          value: 'FI Client Average',
          format: { alignment: 'right' }
        })
      }

      downloadReportRows.push(headers)

      const combinedData = [...this.regulatedTableData, this.reportTotal]

      //Report data
      combinedData.map(row => {
        let data = []

        data.push(row['vendor_name'])
        if (this.canShowProducts) {
          data.push(row['olb_product_name'] ? row['olb_product_name'] : '')
        }
        if (this.canShowBankDetails) {
          data.push({
            value: row['market_measure_bank'],
            format: '#,###'
          })
          data.push({
            value: row['client_avg_bank'],
            format: '#,###'
          })
        }
        if (this.canShowCuDetails) {
          data.push({
            value: row['market_measure_cu'],
            format: '#,###'
          })
          data.push({
            value: row['client_avg_cu'],
            format: '#,###'
          })
        }
        data.push({
          value: row['market_measure'],
          format: '#,###'
        })
        data.push({
          value: row['client_avg'],
          format: '#,###'
        })
        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name: 'Retail Online Banking Vendor Analytics - Client Firmographics',
        subTitle: this.reportSubTitle,
        yearHeaders: yearHeaders,
        data: downloadReportRows
      })
    }
  }
}
</script>
