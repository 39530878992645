<template>
  <div class="no-gutters market-report-container">
    <div class="table-fixed">
      <table class="table table-striped small-font-for-print">
        <thead v-if="regulatedTableData.length">
          <tr>
            <th @click="sortByColumn('vendor_name')" width="10%">
              Vendor Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('vendor_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('previous_market_measure')"
              class="text-right"
              width="5%"
            >
              Previous Market Measure
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('previous_market_measure')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('market_lost')"
              class="text-right"
              width="5%"
            >
              Market Lost
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('market_lost')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('competitive_churn_rate')"
              class="text-right"
              width="5%"
            >
              Competitive Churn Rate
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('competitive_churn_rate')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('consolidation_churn_rate')"
              class="text-right"
              width="5%"
            >
              Consolidation Churn Rate
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('consolidation_churn_rate')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('total_churn_rate')"
              class="text-right"
              width="5%"
            >
              Total Churn Rate
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('total_churn_rate')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('competitive_loss_age')"
              class="text-right"
              width="5%"
            >
              Competitive Loss Age
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('competitive_loss_age')
                  ]"
                >
                </i>
              </span>
            </th>
            <th class="text-right" width="60%">
              <div class="">
                <div class="text-left">
                  <span class="bar-legend enrollment "></span>
                  <span class="px-2">Consolidation Churn Rate</span>
                </div>
                <div class="text-left">
                  <span class="bar-legend lifetime-installs"></span>
                  <span class="px-2">Competitive Churn Rate</span>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="regulatedTableData.length">
          <tr v-for="(data, rank) in regulatedTableData" :key="rank">
            <td class="name-column" :title="data.vendor_name">
              {{ data.vendor_name }}
            </td>
            <td class="text-right">
              {{ data.previous_market_measure | formatValue('#,###') }}
            </td>
            <td class="text-right">
              {{ parseFloat(data.market_lost) | formatValue('#,###') }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.competitive_churn_rate)
                  | formatValue(dataFormat.competitive_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.consolidation_churn_rate)
                  | formatValue(dataFormat.consolidation_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.total_churn_rate)
                  | formatValue(dataFormat.total_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.competitive_loss_age)
                  | formatValue(dataFormat.competitive_loss_age)
              }}
            </td>
            <td
              class="p-0"
              :rowspan="regulatedTableData.length + 1"
              v-if="rank === 0"
            >
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-vacr"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td class="">Grand Total</td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.previous_market_measure)
                  | formatValue(dataFormat.previous_market_measure || '#,###')
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.market_lost)
                  | formatValue(dataFormat.market_lost || '#,###')
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.competitive_churn_rate)
                  | formatValue(dataFormat.competitive_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.consolidation_churn_rate)
                  | formatValue(dataFormat.consolidation_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.total_churn_rate)
                  | formatValue(dataFormat.total_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.competitive_loss_age)
                  | formatValue(dataFormat.competitive_loss_age)
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'VAChurnRate',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    },
    reportOtherData: {
      required: true,
      default: null
    },
    reportTotal: {
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        vendor_name: ['none', 'string'],
        previous_market_measure: ['none', 'float'],
        market_lost: ['none', 'float'],
        competitive_churn_rate: ['none', 'float'],
        consolidation_churn_rate: ['none', 'float'],
        total_churn_rate: ['none', 'float'],
        competitive_loss_age: ['none', 'float']
      }
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  computed: {
    ...mapState('DigitalChannels', ['topVendors', 'topVendorsMax']),
    ...mapGetters('DigitalChannels', ['reportSubTitle']),
    regulatedTableData() {
      let regulatedData = this.reportData ? [...this.reportData] : []

      if (this.reportOtherData && this.reportData.length < this.topVendorsMax) {
        regulatedData.push(this.reportOtherData)
      }

      return regulatedData
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-vacr'
        ) {
          chart.destroy()
        }
      })
      const dataFormat = this.dataFormat

      Highcharts.chart('report-chart-vacr', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0,
            stacking: 'normal'
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(
                this.value,
                dataFormat.consolidation_churn_rate
              )
            }
          }
        },
        series: [
          {
            data: this.regulatedTableData.map(rd =>
              parseFloat(rd.consolidation_churn_rate)
            ),
            color: '#333333',
            pointPlacement: this.pointPlacementValue()
          },
          {
            data: this.regulatedTableData.map(rd =>
              parseFloat(rd.competitive_churn_rate)
            ),
            color: '#A7CA77',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'Vendor Name',
        { value: 'Previous Market Measure', format: { alignment: 'right' } },
        { value: 'Market Lost', format: { alignment: 'right' } },
        { value: 'Competitive Churn Rate', format: { alignment: 'right' } },
        { value: 'Consolidation Churn Rate', format: { alignment: 'right' } },
        { value: 'Total Churn Rate', format: { alignment: 'right' } },
        { value: 'Competitive Loss Age', format: { alignment: 'right' } }
      ]

      downloadReportRows.push(headers)
      const combinedData = [...this.regulatedTableData, this.reportTotal]

      //Report data
      combinedData.map(row => {
        let data = []

        data.push(row['vendor_name'])

        data.push({
          value: row['previous_market_measure'],
          format: '#,###'
        })
        data.push({
          value: row['market_lost'],
          format: 'N2'
        })
        data.push({
          value: row['competitive_churn_rate'],
          format: this.dataFormat['competitive_churn_rate']
        })
        data.push({
          value: row['consolidation_churn_rate'],
          format: this.dataFormat['consolidation_churn_rate']
        })
        data.push({
          value: row['total_churn_rate'],
          format: this.dataFormat['total_churn_rate']
        })
        data.push({
          value: row['competitive_loss_age'],
          format: this.dataFormat['competitive_loss_age']
        })
        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name: 'Retail Mobile Banking Vendor Analytics - Churn Rate',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
