var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-gutters market-report-container"},[_c('div',{},[_c('table',{staticClass:"table table-striped"},[(_vm.reportTableData.length)?_c('thead',[_c('tr',[_c('th',{attrs:{"width":"20%"},on:{"click":function($event){return _vm.sortByColumn('feature_segment')}}},[_vm._v(" Feature Segment "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('feature_segment')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"10%"},on:{"click":function($event){return _vm.sortByColumn('market_total')}}},[_vm._v(" Market Total "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('market_total')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"10%"},on:{"click":function($event){return _vm.sortByColumn('ratings_count')}}},[_vm._v(" Ratings Count "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('ratings_count')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"10%"},on:{"click":function($event){return _vm.sortByColumn('ratings_to_installs')}}},[_vm._v(" Ratings to Installs "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('ratings_to_installs')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"10%"},on:{"click":function($event){return _vm.sortByColumn('app_ratings')}}},[_vm._v(" App Rating "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('app_ratings')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"40%"}},[_vm._v(" App Rating ")])])]):_vm._e(),(_vm.reportTableData.length)?_c('tbody',{ref:"reportTableBody"},[_vm._l((_vm.reportTableData),function(data,index){return _c('tr',{key:index},[_c('td',{staticClass:"name-column font-weight-bold"},[_vm._v(" "+_vm._s(data.feature_segment)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.market_total,_vm.dataFormat.market_total))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.ratings_count,_vm.dataFormat.ratings_count))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.ratings_to_installs,_vm.dataFormat.ratings_to_installs))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.app_ratings,_vm.dataFormat.app_ratings))+" ")]),_c('td',{staticClass:"p-0",attrs:{"rowspan":_vm.reportData.length}},[(_vm.showChart)?_c('div',{ref:"reportChartContainer",refInFor:true,staticClass:"report-chart-container"},[_c('div',{attrs:{"id":"report-chart-iafss"}})]):_vm._e()])])}),_c('tr',{staticClass:"report-footer"},[_c('td',{staticClass:"name-column font-weight-bold"},[_vm._v(" Grand Total ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.market_total,_vm.dataFormat.market_total))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.ratings_count,_vm.dataFormat.ratings_count))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.ratings_to_installs,_vm.dataFormat.ratings_to_installs))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.app_ratings,_vm.dataFormat.app_ratings))+" ")])])],2):_c('tbody',[_vm._m(0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('div',{staticClass:"text-center text-muted"},[_vm._v("No Records found")])])])}]

export { render, staticRenderFns }