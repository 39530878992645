<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="reportData.length">
          <tr>
            <th :width="canShowProducts ? '5%' : '10%'">
              <div>Rank</div>
            </th>
            <th
              @click="sortByColumn('vendor_name')"
              :width="canShowProducts ? '25%' : '30%'"
            >
              <span>Vendor Name</span>
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('vendor_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('product_name')"
              width="20%"
              v-if="canShowProducts"
            >
              <span>Product Name</span>
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('product_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('market_total')"
              class="text-right"
              :width="canShowProducts ? '10%' : '15%'"
            >
              <span>Market Total</span>
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('market_total')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('version_count')"
              class="text-right"
              :width="canShowProducts ? '10%' : '15%'"
            >
              <span>Number Of Versions</span>
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('version_count')
                  ]"
                >
                </i>
              </span>
            </th>
            <th class="text-right" width="30%">
              Distinct Count Of Version
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="regulatedTableData.length">
          <tr v-for="(data, rank) in regulatedTableData" :key="rank">
            <td class="">
              {{ rank + 1 }}
            </td>
            <td class="name-column" :title="data.vendor_name">
              {{ data.vendor_name }}
            </td>
            <td
              class="name-column"
              :title="data.product_name"
              v-if="canShowProducts"
            >
              {{ data.product_name }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.market_total)
                  | formatValue(dataFormat.market_total)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.version_count)
                  | formatValue(dataFormat.version_count)
              }}
            </td>
            <td
              class="p-0"
              :rowspan="regulatedTableData.length + 1"
              v-if="rank === 0"
            >
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-vavc" ref="reportChart"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td :colspan="canShowProducts ? 3 : 2" class="">Grand Total</td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.market_total)
                  | formatValue(dataFormat.market_total)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.version_count)
                  | formatValue(dataFormat.version_count)
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="canShowProducts ? 6 : 5">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'VAVersionCount',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    },
    reportOtherData: {
      required: true,
      default: null
    },
    reportTotal: {
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        vendor_name: ['none', 'string'],
        product_name: ['none', 'string'],
        market_total: ['none', 'float'],
        version_count: ['none', 'float']
      }
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  computed: {
    ...mapState('DigitalChannels', ['topVendors', 'topVendorsMax']),
    ...mapGetters('DigitalChannels', ['canShowProducts', 'reportSubTitle']),
    regulatedTableData() {
      let regulatedData = this.reportData ? [...this.reportData] : []

      if (this.reportOtherData && this.reportData.length < this.topVendorsMax) {
        regulatedData.push(this.reportOtherData)
      }
      return regulatedData
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-vavc'
        ) {
          chart.destroy()
        }
      })
      const dataFormat = this.dataFormat
      Highcharts.chart('report-chart-vavc', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0,
            dataLabels: {
              enabled: false
            }
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(
                this.value,
                dataFormat.version_count
              )
            }
          },
          title: {
            text: null
          }
        },
        series: [
          {
            data: this.regulatedTableData.map(rd =>
              parseFloat(rd.version_count)
            ),
            color: '#A7CA77',
            negativeColor: '#333333',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'Rank',
        'Vendor Name',
        { value: 'Market Total', format: { alignment: 'right' } },
        { value: 'Number Of Versions', format: { alignment: 'right' } }
      ]
      if (this.canShowProducts) headers.splice(2, 0, 'Product Name')
      downloadReportRows.push(headers)
      const combinedData = [...this.regulatedTableData, this.reportTotal]
      //Report data
      combinedData.map((row, index) => {
        let data = []
        data.push(index === combinedData.length - 1 ? '' : index + 1)
        data.push(row['vendor_name'])
        if (this.canShowProducts) {
          data.push(row['product_name'] ? row['product_name'] : '')
        }
        data.push({
          value: row['market_total'],
          format: '#,###'
        })
        data.push({
          value: row['version_count'],
          format: this.dataFormat['version_count']
        })

        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name: 'Retail Mobile Banking Vendor Analytics - Version Count',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
