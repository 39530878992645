<template>
  <div class="no-gutters market-report-container">
    <div class="table-responsive landscape-trend-table">
      <table class="table table-striped small-font-for-print">
        <thead v-if="reportData.length">
          <tr v-if="!isDateLevelSetToYear">
            <th></th>
            <th
              v-for="(year, periodIndex) in Object.keys(getYearHeaders)"
              :key="'year_' + periodIndex"
              :colspan="getYearHeaders[year]"
              class="text-center"
            >
              {{ year }}
            </th>
            <th></th>
          </tr>
          <tr>
            <th>FI Type</th>
            <th
              @click="sortByColumn(header)"
              v-for="(header, periodIndex) in reportHeaderKeys"
              :key="'subHead_' + periodIndex"
              :class="addBorderClass(periodIndex)"
            >
              {{ getHeader(header) }}
              <span>
                <i
                  :class="['fa fa-regular sort-icon', getSortIconClass(header)]"
                >
                </i>
              </span>
            </th>
            <th class="text-right chart-col">
              <div class="d-flex justify-content-around">
                <div
                  v-for="(chartPeriod, chartPeriodIndex) in getChartHeaderData"
                  :key="'chart_' + chartPeriodIndex"
                >
                  {{ chartPeriod }}
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="reportTableData.length">
          <tr
            v-for="(data, rowIndex) in reportTableData"
            :key="'data_' + rowIndex"
          >
            <td class="name-column font-weight-bold">
              {{ data.fi_type }}
            </td>
            <td
              v-for="(dataKey, rowDataIndex) in reportHeaderKeys"
              :key="'col_' + rowDataIndex"
              :class="addBorderClass(rowDataIndex)"
            >
              {{ data[dataKey] | formatValue('P1') }}
            </td>
            <td class="p-0">
              <spline-chart
                :headers="reportHeaders"
                :chartData="data"
                :chartIndex="rowIndex"
                :dataFormat="{ format: 'P1' }"
              ></spline-chart>
            </td>
          </tr>
          <tr class="report-footer">
            <td class="name-column">Grand Total</td>
            <td
              v-for="(dataKey, rowDataIndex) in reportHeaderKeys"
              :key="'grandTotal_' + rowDataIndex"
              :class="addBorderClass(rowDataIndex)"
            >
              {{ reportTotal[dataKey] | formatValue('P1') }}
            </td>
            <td class="p-0">
              <spline-chart
                :headers="reportHeaders"
                :chartData="reportTotal"
                :chartIndex="0"
                :dataFormat="{ format: 'P1' }"
              ></spline-chart>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <landscape-mode></landscape-mode>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
// ui components
import SplineChart from '@/modules/digital_channels/components/SplineChart'
import LandscapeMode from '@/modules/core/components/LandscapeMode'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'IAAdoptionTrendType',
  components: { SplineChart, LandscapeMode },
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    },
    reportHeaders: {
      required: true,
      default: []
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {}
    }
  },
  created() {
    this.reportHeaderKeys.forEach(key => {
      this.sortOrder[key] = ['none', 'float']
    })
  },
  computed: {
    ...mapGetters('DigitalChannels', ['reportSubTitle']),
    reportTableData() {
      return this.reportData.filter(data => data.fi_type !== 'Grand Total')
    },

    reportTotal() {
      return this.reportData.find(data => data.fi_type === 'Grand Total')
    }
  },
  mounted() {
    this.markIfWithinPrintableRange()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  updated() {
    this.prepareDownloadReport()
  },
  methods: {
    ...mapMutations('DigitalChannels', [
      'setDownloadDCReportData',
      'setReportIsPrintable'
    ]),
    markIfWithinPrintableRange() {
      if (this.reportHeaderKeys && this.reportHeaderKeys.length <= 8)
        this.setReportIsPrintable(true)
      else this.setReportIsPrintable(false)
    },
    addBorderClass(periodIndex) {
      return this.getBorderClass(periodIndex, 'adoption_')
    },
    prepareDownloadReport() {
      let downloadReportRows = []
      let yearHeaders = []
      //year headers

      if (!this.isDateLevelSetToYear) {
        for (let key of Object.keys(this.getYearHeaders)) {
          yearHeaders.push({ value: key, colSpan: this.getYearHeaders[key] })
        }
      }

      //Headers
      let headers = ['FI Type']
      for (let key of this.reportHeaderKeys) {
        headers.push(this.getHeader(key))
      }
      downloadReportRows.push(headers)
      //Report data
      this.populateExcelRows(
        this.reportTableData,
        'fi_type',
        'P1',
        downloadReportRows
      )

      // Grand Total
      this.populateExcelRows(
        [this.reportTotal],
        'fi_type',
        'P1',
        downloadReportRows
      )

      this.setDownloadDCReportData({
        name:
          'Retail Mobile Banking Institution Analytics - Adoption Trend Type',
        subTitle: this.reportSubTitle,
        yearHeaders: yearHeaders,
        data: downloadReportRows
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.chart-col {
  width: 500px;
}
</style>
