var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-gutters market-report-container"},[_c('div',{},[_c('table',{staticClass:"table table-striped"},[(_vm.reportTableData.length)?_c('thead',[_c('tr',[_c('th',{attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('name')}}},[_vm._v(" Asset Group "),_c('span',[_c('i',{class:['fa fa-regular sort-icon', _vm.getSortIconClass('name')]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('market_count')}}},[_vm._v(" Market Count "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('market_count')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('olb_count')}}},[_vm._v(" FIs w/ OLB "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('olb_count')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('olb_count_percentage')}}},[_vm._v(" FIs w/ OLB % "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('olb_count_percentage')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"40%"}},[_vm._v(" FIs w/ OLB % ")])])]):_vm._e(),(_vm.reportTableData.length)?_c('tbody',{ref:"reportTableBody"},[_vm._l((_vm.reportTableData),function(data,asset_group){return _c('tr',{key:asset_group},[_c('td',{staticClass:"name-column font-weight-bold"},[_vm._v(" "+_vm._s(data.name)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.market_count,_vm.dataFormat.market_count))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.olb_count,_vm.dataFormat.olb_count))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.olb_count_percentage,_vm.dataFormat.olb_count_percentage))+" ")]),_c('td',{staticClass:"p-0",attrs:{"rowspan":_vm.reportData.length}},[(_vm.showChart)?_c('div',{ref:"reportChartContainer",refInFor:true,staticClass:"report-chart-container"},[_c('div',{attrs:{"id":"report-chart-olbiaa"}})]):_vm._e()])])}),_c('tr',{staticClass:"report-footer"},[_c('td',[_vm._v("Grand Total")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.market_count,_vm.dataFormat.market_count))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.olb_count,_vm.dataFormat.olb_count))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.olb_count_percentage,_vm.dataFormat.olb_count_percentage))+" ")])])],2):_c('tbody',[_vm._m(0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('div',{staticClass:"text-center text-muted"},[_vm._v("No Records found")])])])}]

export { render, staticRenderFns }