// global
//import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
// api
import digitalChannelsAPI from '@/api/finapps/digital.channels'
// utilities
import _ from 'lodash'

const ReportShow = {
  mounted() {
    this.fetchOverviewText()
  },
  computed: {
    ...mapState({
      isSidebarExpanded: state => state.isSidebarExpanded
    }),
    ...mapState('DigitalChannels', [
      'selectedReport',
      'dateLevel',
      'listenerList'
    ]),
    ...mapGetters(['isAsideOpen']),

    // Methods specific to IA reports - start
    regulatedTableData() {
      return this.reportData.filter(data => data.asset_group !== 0)
    },

    grandTotal() {
      return this.reportData.find(data => data.asset_group === 0)
    },
    // Method is specific to IA reports - end
    // Methods Specific to Trend Reports -Start
    getChartHeaderData() {
      return this.isDateLevelSetToYear
        ? this.reportHeaders
        : this.getYearHeaderKeys
    },
    reportHeaderKeys() {
      return _.keys(this.reportHeaders)
    },
    getYearHeaders() {
      const data = this.reportHeaders
      const years = _.keys(data).map(key => {
        let item = '' + data[key]
        return item.substring(0, item.indexOf(' -'))
      })
      const yearsAndFreq = _.countBy(years)
      return yearsAndFreq
    },
    getYearHeaderKeys() {
      return _.keys(this.getYearHeaders)
    },
    isDateLevelSetToYear() {
      return this.dateLevel === 'Year'
    },
    isDateLevelSetToMonth() {
      return this.dateLevel === 'Month'
    }
    // Methods Specific to Trend Reports - End
  },
  methods: {
    ...mapMutations('DigitalChannels', [
      'addToListenerList',
      'setListenerList'
    ]),
    sortByColumn(colName) {
      const assetGrpMap = {
        '< $100M': 0,
        '$100M - $500M': 1,
        '$500M - $1B': 2,
        '$1B - $10B': 3,
        '$10B - $100B': 4,
        '> $100B': 5
      }
      const featureSegMap = {
        Basic: 0,
        Standard: 1,
        Advanced: 2,
        Leading: 3
      }
      let order = this.sortOrder[colName][0]
      order = order === 'none' ? 'asc' : order === 'asc' ? 'desc' : 'asc'
      let orderFn = function(item) {
        return parseFloat(item[colName])
      }
      if (this.sortOrder[colName][1] === 'asset_group')
        orderFn = function(item) {
          return assetGrpMap[item[colName]]
        }
      else if (this.sortOrder[colName][1] === 'feature_segment')
        orderFn = function(item) {
          return featureSegMap[item[colName]]
        }
      else if (this.sortOrder[colName][1] === 'string')
        orderFn = function(item) {
          return item[colName].toLowerCase()
        }
      this.reportData = _.orderBy(this.reportData, orderFn, order)
      this.sortOrder[colName][0] = order
    },
    getSortIconClass(colName) {
      return this.sortOrder[colName][0] === 'none'
        ? 'fa-sort'
        : this.sortOrder[colName][0] === 'asc'
        ? 'fa-sort-up'
        : 'fa-sort-down'
    },
    populateExcelRows(inputData, colName, format, downloadReportRows) {
      inputData.map(row => {
        let data = []
        data.push(row[colName])
        for (let key of this.reportHeaderKeys) {
          data.push({ value: row[key], format: format })
        }
        downloadReportRows.push(data)
      })
    },
    // Methods Specific to Trend Reports -Start
    getHeader(header) {
      const monthList = {
        M1: 'Jan',
        M2: 'Feb',
        M3: 'Mar',
        M4: 'Apr',
        M5: 'May',
        M6: 'June',
        M7: 'July',
        M8: 'Aug',
        M9: 'Sep',
        M10: 'Oct',
        M11: 'Nov',
        M12: 'Dec'
      }
      const text = this.reportHeaders[header]
      if (this.isDateLevelSetToYear) return text
      else if (this.isDateLevelSetToMonth) return monthList[text.substring(7)]
      else return text.substring(7)
    },
    isStartOfYear(prevKey, currentKey, elementName) {
      const year = currentKey.replace(elementName, '').substring(0, 4)
      if (prevKey !== undefined && prevKey.includes(year)) return false
      else return true
    },
    getBorderClass(periodIndex, elementName) {
      const result =
        !this.isDateLevelSetToYear &&
        this.isStartOfYear(
          this.reportHeaderKeys[periodIndex - 1],
          this.reportHeaderKeys[periodIndex],
          elementName
        )
          ? 'left-border text-right trend-col-others'
          : 'text-right trend-col-others'
      return result
    },
    // Methods Specific to Trend Reports - End
    fetchOverviewText() {
      return digitalChannelsAPI.overviewText(this.selectedReport).then(res => {
        this.$emit('overviewText', res.overview)
      })
    },
    marginTopValue() {
      let divisor = this.regulatedTableData.length > 70 ? 1.25 : 1.5
      return (this.regulatedTableData.length / divisor) * -1
    },
    chartHeight() {
      if (this.$refs.reportTableBody) {
        return this.$refs.reportTableBody.offsetHeight
      } else {
        return 0
      }
    },
    pointPlacementValue() {
      return this.regulatedTableData.length / -125
    },
    redrawChartOnPrint() {
      setTimeout(
        function() {
          if (window.matchMedia) {
            let mediaQueryList = window.matchMedia('print')
            let listenerFn = () => {
              this.drawChart()
            }
            if (this.listenerList.length > 0) {
              this.listenerList.map(item => {
                mediaQueryList.removeListener(item)
              })
              this.setListenerList([])
            }
            this.fn = listenerFn
            this.addToListenerList(listenerFn)
            mediaQueryList.addListener(this.fn)
          }
        }.bind(this),
        0
      )
    },
    clearListener() {
      setTimeout(
        function() {
          if (window.matchMedia) {
            let mediaQueryList = window.matchMedia('print')

            mediaQueryList.removeListener(this.fn)
          }
        }.bind(this),
        0
      )
    }
  },
  watch: {
    reportData: {
      immediate: true,
      handler: function(newVal) {
        if (newVal.length) {
          this.$nextTick(() => {
            if (typeof this.drawChart === 'function') {
              this.drawChart()
            }
          })
        }
      }
    },

    isSidebarExpanded: {
      handler: function() {
        setTimeout(
          function() {
            if (typeof this.drawChart === 'function') {
              this.drawChart()
            }
          }.bind(this),
          200
        )
      }
    },
    isAsideOpen: {
      handler: function() {
        setTimeout(
          function() {
            if (typeof this.drawChart === 'function') {
              this.drawChart()
            }
          }.bind(this),
          200
        )
      }
    }
  }
}

export default ReportShow
