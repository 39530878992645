var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-gutters market-report-container"},[_c('div',{},[_c('table',{staticClass:"table table-striped"},[(_vm.reportTableData.length)?_c('thead',[_c('tr',[_c('th',{attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('name')}}},[_vm._v(" FI Asset Group "),_c('span',[_c('i',{class:['fa fa-regular sort-icon', _vm.getSortIconClass('name')]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('app_age_bank')}}},[_vm._v(" Bank Avg MB Age "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('app_age_bank')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('app_age_cu')}}},[_vm._v(" Credit Union Avg MB Age "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('app_age_cu')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('difference')}}},[_vm._v(" MB Age Differential(CU-Bank) "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('difference')
                ]})])]),_vm._m(0)])]):_vm._e(),(_vm.reportTableData.length)?_c('tbody',{ref:"reportTableBody"},[_vm._l((_vm.reportTableData),function(data,asset_group){return _c('tr',{key:asset_group},[_c('td',{staticClass:"name-column font-weight-bold"},[_vm._v(" "+_vm._s(data.name)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.app_age_bank,_vm.dataFormat.app_age_bank))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(parseFloat(data.app_age_cu),_vm.dataFormat.app_age_cu))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(parseFloat(data.difference),_vm.dataFormat.difference))+" ")]),_c('td',{staticClass:"p-0",attrs:{"rowspan":_vm.reportData.length}},[(_vm.showChart)?_c('div',{ref:"reportChartContainer",refInFor:true,staticClass:"report-chart-container"},[_c('div',{ref:"reportChart",refInFor:true,attrs:{"id":"report-chart-iaaft"}})]):_vm._e()])])}),_c('tr',{staticClass:"report-footer"},[_c('td',{},[_vm._v("Grand Total")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.app_age_bank,_vm.dataFormat.app_age_bank))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.app_age_cu,_vm.dataFormat.app_age_cu))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.difference,_vm.dataFormat.difference))+" ")])])],2):_c('tbody',[_vm._m(1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"text-right",attrs:{"width":"40%"}},[_c('div',{staticClass:"chart-container-header"},[_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"text-left"},[_c('span',{staticClass:"bar-legend lifetime-installs "}),_c('span',{staticClass:"px-2"},[_vm._v("Banks")])]),_c('div',{staticClass:"text-left"},[_c('span',{staticClass:"bar-legend enrollment"}),_c('span',{staticClass:"px-2"},[_vm._v("Credit Unions")])])]),_c('div',{staticClass:"text-right"},[_c('span',{staticClass:"px-2"},[_vm._v("Mobile Banking Age")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('div',{staticClass:"text-center text-muted"},[_vm._v("No Records found")])])])}]

export { render, staticRenderFns }