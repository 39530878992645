<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="reportData.length">
          <tr>
            <th @click="sortByColumn('name')" width="20%">
              Feature
              <span>
                <i
                  :class="['fa fa-regular sort-icon', getSortIconClass('name')]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('previous_pct')"
              class="text-right"
              width="12%"
            >
              Previous % w/ Feature
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('previous_pct')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('current_pct')"
              class="text-right"
              width="12%"
            >
              Current % w/ Feature
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('current_pct')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('added_feature')"
              class="text-right"
              width="10%"
            >
              # Added Feature
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('added_feature')
                  ]"
                >
                </i>
              </span>
            </th>
            <th class="text-right" width="46%">
              FIs Adding Feature #
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="reportData.length">
          <tr v-for="(data, key) in reportData" :key="key">
            <td class="name-column font-weight-bold">
              {{ data.name }}
            </td>
            <td class="text-right">
              {{ data.previous_pct | formatValue(dataFormat['previous_pct']) }}
            </td>
            <td class="text-right">
              {{ data.current_pct | formatValue(dataFormat['current_pct']) }}
            </td>
            <td class="text-right">
              {{
                data.added_feature | formatValue(dataFormat['added_feature'])
              }}
            </td>
            <td class="p-0" :rowspan="reportData.length + 1">
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-iafl"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td colspan="4"></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
// global
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'IAFeatureLeaderboard',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    }
  },
  computed: {
    ...mapGetters('DigitalChannels', ['reportSubTitle'])
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        name: ['none', 'string'],
        previous_pct: ['none', 'float'],
        current_pct: ['none', 'float'],
        added_feature: ['none', 'float']
      }
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-iafl'
        ) {
          chart.destroy()
        }
      })
      const dataFormat = this.dataFormat
      Highcharts.chart('report-chart-iafl', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatLabel')(
                this.value,
                dataFormat.added_feature
              )
            }
          },
          title: {
            text: null
          }
        },
        series: [
          {
            name: '',
            data: this.reportData.map(rd => rd.added_feature),
            color: '#A7CA77',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'Feature',
        'Previous % w/ Feature',
        'Current % w/ Feature',
        '# Added Feature'
      ]

      downloadReportRows.push(headers)
      //Report data
      this.reportData.map(row => {
        let data = []
        data.push(row['name'])
        data.push({
          value: row['previous_pct'],
          format: this.dataFormat['previous_pct']
        })
        data.push({
          value: row['current_pct'],
          format: this.dataFormat['current_pct']
        })
        data.push({
          value: row['added_feature'],
          format: this.dataFormat['added_feature']
        })

        downloadReportRows.push(data)
      })
      this.setDownloadDCReportData({
        name:
          'Retail Mobile Banking Institution Analytics - Feature Leaderboard',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
