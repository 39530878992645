<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="regulatedTableData.length">
          <tr>
            <td width="10%"></td>
            <td
              colspan="2"
              class="left-border text-center no-bottom-padding"
              width="20%"
            >
              <b>Banks</b>
            </td>
            <td
              colspan="2"
              class="left-border text-center no-bottom-padding "
              width="20%"
            >
              <b>Credit Unions</b>
            </td>
            <th class="text-right left-border" width="46%" rowspan="2">
              <div class="chart-container-header">
                <div class="text-left">
                  <div class="text-left">
                    <span class="bar-legend lifetime-installs "></span>
                    <span class="px-2">Banks</span>
                  </div>
                  <div class="text-left">
                    <span class="bar-legend enrollment"></span>
                    <span class="px-2">Credit Unions</span>
                  </div>
                </div>
                <div class="text-right">
                  <span class="px-2">Competitive Churn Rate</span>
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th @click="sortByColumn('vendor_name')" width="10%">
              Vendor Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('vendor_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('bank_competitive_churn_rate')"
              class="text-right left-border "
              width="12%"
            >
              Competitive Churn Rate
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('bank_competitive_churn_rate')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('bank_competitive_loss_age')"
              class="text-right"
              width="10%"
            >
              Competitive Loss Age
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('bank_competitive_loss_age')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('cu_competitive_churn_rate')"
              class="text-right left-border "
              width="12%"
            >
              Competitive Churn Rate
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('cu_competitive_churn_rate')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('cu_competitive_loss_age')"
              class="text-right "
              width="10%"
            >
              Competitive Loss Age
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('cu_competitive_loss_age')
                  ]"
                >
                </i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="regulatedTableData.length">
          <tr v-for="(data, rank) in regulatedTableData" :key="rank">
            <td class="name-column" :title="data.vendor">
              {{ data.vendor_name }}
            </td>

            <td class="text-right left-border ">
              {{
                data.bank_competitive_churn_rate
                  | formatValue(dataFormat.bank_competitive_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                data.bank_competitive_loss_age
                  | formatValue(dataFormat.bank_competitive_loss_age)
              }}
            </td>
            <td class="text-right left-border ">
              {{
                data.cu_competitive_churn_rate
                  | formatValue(dataFormat.cu_competitive_churn_rate)
              }}
            </td>
            <td class="text-right ">
              {{
                data.cu_competitive_loss_age
                  | formatValue(dataFormat.cu_competitive_loss_age)
              }}
            </td>
            <td
              class="p-0"
              :rowspan="regulatedTableData.length + 1"
              v-if="rank === 0"
            >
              <div
                class="report-chart-container "
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-vacft" class="left-border"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td>Grand Total</td>
            <td class="text-right left-border ">
              {{
                parseFloat(reportTotal.bank_competitive_churn_rate)
                  | formatValue(dataFormat.bank_competitive_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.bank_competitive_loss_age)
                  | formatValue(dataFormat.bank_competitive_loss_age)
              }}
            </td>
            <td class="text-right left-border ">
              {{
                parseFloat(reportTotal.cu_competitive_churn_rate)
                  | formatValue(dataFormat.cu_competitive_churn_rate)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.cu_competitive_loss_age)
                  | formatValue(dataFormat.cu_competitive_loss_age)
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
// global
import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'VAChurnFIType',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    },
    reportOtherData: {
      required: true,
      default: null
    },
    reportTotal: {
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        vendor_name: ['none', 'string'],
        bank_competitive_churn_rate: ['none', 'float'],
        bank_competitive_loss_age: ['none', 'float'],
        cu_competitive_churn_rate: ['none', 'float'],
        cu_competitive_loss_age: ['none', 'float']
      }
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  computed: {
    ...mapState('DigitalChannels', ['topVendors', 'topVendorsMax']),
    ...mapGetters('DigitalChannels', ['reportSubTitle']),
    regulatedTableData() {
      let regulatedData = this.reportData ? [...this.reportData] : []

      if (this.reportOtherData && this.reportData.length < this.topVendorsMax) {
        regulatedData.push(this.reportOtherData)
      }

      return regulatedData
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),

    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-vacft'
        ) {
          chart.destroy()
        }
      })
      const dataFormat = this.dataFormat
      Highcharts.chart('report-chart-vacft', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            groupPadding: 0
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(
                this.value,
                dataFormat.bank_competitive_churn_rate
              )
            }
          },
          title: {
            text: null
          }
        },
        series: [
          {
            animation: false,
            data: this.regulatedTableData.map(
              rd => rd.bank_competitive_churn_rate
            ),
            color: '#A7CA77',
            pointPlacement: this.pointPlacementValue()
          },
          {
            animation: false,
            data: this.regulatedTableData.map(rd =>
              parseFloat(rd.cu_competitive_churn_rate)
            ),
            color: '#333333',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []
      //year Headers
      let yearHeaders = []
      yearHeaders.push({ value: 'Banks', colSpan: 2 })
      yearHeaders.push({ value: 'Credit Unions', colSpan: 2 })
      //Headers
      let headers = [
        'Vendor Name',
        { value: 'Competitive Churn Rate', format: { alignment: 'right' } },
        { value: 'Competitive Loss Age', format: { alignment: 'right' } },
        { value: 'Competitive Churn Rate', format: { alignment: 'right' } },
        { value: 'Competitive Loss Age', format: { alignment: 'right' } }
      ]

      downloadReportRows.push(headers)
      const combinedData = [...this.regulatedTableData, this.reportTotal]

      //Report data
      combinedData.map(row => {
        let data = []

        data.push(row['vendor_name'])

        data.push({
          value: row['bank_competitive_churn_rate'],
          format: this.dataFormat['bank_competitive_churn_rate']
        })
        data.push({
          value: row['bank_competitive_loss_age'],
          format: this.dataFormat['bank_competitive_loss_age']
        })
        data.push({
          value: row['cu_competitive_churn_rate'],
          format: this.dataFormat['cu_competitive_churn_rate']
        })
        data.push({
          value: row['cu_competitive_loss_age'],
          format: this.dataFormat['cu_competitive_loss_age']
        })

        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name: 'Retail Mobile Banking Vendor Analytics - Churn FI Type',
        subTitle: this.reportSubTitle,
        yearHeaders: yearHeaders,
        data: downloadReportRows
      })
    }
  }
}
</script>
<style lang="scss">
.market-report-container {
  table {
    .chart-container-header {
      display: flex;
    }
    .left-border {
      border-left: 1px solid black;
    }
    .no-bottom-padding {
      padding-bottom: 0;
    }
  }
}
</style>
