var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-gutters market-report-container"},[_c('div',{},[_c('table',{staticClass:"table table-striped"},[(_vm.reportTableData.length)?_c('thead',[_c('tr',[_c('th',{attrs:{"width":"17%"},on:{"click":function($event){return _vm.sortByColumn('feature_segment')}}},[_vm._v(" Feature Segment "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('feature_segment')
                ]})])]),_c('th',{staticClass:"text-right no-wrap",attrs:{"width":"15%"},on:{"click":function($event){return _vm.sortByColumn('market_total')}}},[_vm._v(" Market Total "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('market_total')
                ]})])]),_c('th',{staticClass:"text-right no-wrap",attrs:{"width":"12%"},on:{"click":function($event){return _vm.sortByColumn('lifetime_installs')}}},[_vm._v(" Lifetime Installs % "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('lifetime_installs')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"12%"},on:{"click":function($event){return _vm.sortByColumn('customer_enrollment')}}},[_vm._v(" Enrollment "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('customer_enrollment')
                ]})])]),_vm._m(0)])]):_vm._e(),(_vm.reportTableData.length)?_c('tbody',{ref:"reportTableBody"},[_vm._l((_vm.reportTableData),function(data,index){return _c('tr',{key:index},[_c('td',{staticClass:"name-column font-weight-bold"},[_vm._v(" "+_vm._s(data.feature_segment)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.market_total,_vm.dataFormat.market_total))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.lifetime_installs,_vm.dataFormat.lifetime_installs))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.customer_enrollment,_vm.dataFormat.customer_enrollment))+" ")]),_c('td',{staticClass:"p-0",attrs:{"rowspan":_vm.reportData.length}},[(_vm.showChart)?_c('div',{ref:"reportChartContainer",refInFor:true,staticClass:"report-chart-container"},[_c('div',{attrs:{"id":"report-chart-iafse"}})]):_vm._e()])])}),_c('tr',{staticClass:"report-footer"},[_c('td',{staticClass:"name-column font-weight-bold"},[_vm._v(" Grand Total ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.market_total,_vm.dataFormat.market_total))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.lifetime_installs,_vm.dataFormat.lifetime_installs))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(_vm.reportTotal.customer_enrollment,_vm.dataFormat.customer_enrollment))+" ")])])],2):_c('tbody',[_vm._m(1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_c('div',{attrs:{"width":"44%"}},[_c('div',{staticClass:"text-left"},[_c('span',{staticClass:"bar-legend enrollment"}),_c('span',{staticClass:"px-2"},[_vm._v("Enrollment")])]),_c('div',{staticClass:"text-left"},[_c('span',{staticClass:"bar-legend lifetime-installs"}),_c('span',{staticClass:"px-2"},[_vm._v("Lifetime Installs %")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('div',{staticClass:"text-center text-muted"},[_vm._v("No Records found")])])])}]

export { render, staticRenderFns }