<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table
        :class="[
          'table',
          'table-striped',
          canShowProducts ? 'small-font-for-print' : ''
        ]"
      >
        <thead v-if="reportData.length">
          <tr>
            <td :colspan="canShowProducts ? 3 : 2"></td>
            <td colspan="6" class="text-center no-bottom-padding">
              <b>Release Age Groups</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <th width="3%">Rank</th>
            <th
              @click="sortByColumn('vendor_name')"
              :width="canShowProducts ? '10%' : '15%'"
            >
              Vendor Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('vendor_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('product_name')"
              width="10%"
              v-if="canShowProducts"
            >
              Product Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('product_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('months_lt_3')"
              class="text-right"
              width="7%"
            >
              &lt; 3 months
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('months_lt_3')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('months_3_6')"
              class="text-right"
              width="7%"
            >
              3 - 6 months
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('months_3_6')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('months_6_12')"
              class="text-right"
              width="7%"
            >
              6 - 12 months
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('months_6_12')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('months_12_18')"
              class="text-right"
              width="7%"
            >
              12 - 18 months
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('months_12_18')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('months_18_24')"
              class="text-right"
              width="7%"
            >
              18 - 24 months
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('months_18_24')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('months_gt_24')"
              class="text-right"
              width="7%"
            >
              24+ months
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('months_gt_24')
                  ]"
                >
                </i>
              </span>
            </th>
            <th class="text-right" :width="canShowProducts ? '35%' : '40%'">
              % Releases &lt; 6 months
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="reportData.length">
          <tr v-for="(data, rank) in regulatedTableData" :key="rank">
            <td class="">
              {{ rank + 1 }}
            </td>
            <td class="name-column" :title="data.vendor_name">
              {{ data.vendor_name }}
            </td>
            <td
              class="name-column"
              :title="data.product_name"
              v-if="canShowProducts"
            >
              {{ data.product_name }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.months_lt_3)
                  | formatValue(dataFormat.months_lt_3)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.months_3_6) | formatValue(dataFormat.months_3_6)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.months_6_12)
                  | formatValue(dataFormat.months_6_12)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.months_12_18)
                  | formatValue(dataFormat.months_12_18)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.months_18_24)
                  | formatValue(dataFormat.months_18_24)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.months_gt_24)
                  | formatValue(dataFormat.months_gt_24)
              }}
            </td>
            <td
              class="p-0"
              :rowspan="regulatedTableData.length + 1"
              v-if="rank === 0"
            >
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-vavag" ref="reportChart"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td :colspan="canShowProducts ? 3 : 2" class="">Grand Total</td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.months_lt_3)
                  | formatValue(dataFormat.months_lt_3)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.months_3_6)
                  | formatValue(dataFormat.months_3_6)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.months_6_12)
                  | formatValue(dataFormat.months_6_12)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.months_12_18)
                  | formatValue(dataFormat.months_12_18)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.months_18_24)
                  | formatValue(dataFormat.months_18_24)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(reportTotal.months_gt_24)
                  | formatValue(dataFormat.months_gt_24)
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td :colspan="canShowProducts ? 6 : 5">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'VAVersionAgeGroups',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    },
    reportOtherData: {
      required: true,
      default: null
    },
    reportTotal: {
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        vendor_name: ['none', 'string'],
        product_name: ['none', 'string'],
        months_lt_3: ['none', 'float'],
        months_3_6: ['none', 'float'],
        months_6_12: ['none', 'float'],
        months_12_18: ['none', 'float'],
        months_18_24: ['none', 'float'],
        months_gt_24: ['none', 'float']
      }
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  computed: {
    ...mapState('DigitalChannels', ['topVendors', 'topVendorsMax']),
    ...mapGetters('DigitalChannels', ['canShowProducts', 'reportSubTitle']),
    regulatedTableData() {
      let regulatedData = this.reportData ? [...this.reportData] : []

      if (this.reportOtherData && this.reportData.length < this.topVendorsMax) {
        regulatedData.push(this.reportOtherData)
      }
      return regulatedData
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-vavag'
        ) {
          chart.destroy()
        }
      })
      const dataFormat = this.dataFormat
      Highcharts.chart('report-chart-vavag', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0,
            dataLabels: {
              enabled: false
            }
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(
                this.value,
                dataFormat.months_lt_6
              )
            }
          },
          title: {
            text: null
          }
        },
        series: [
          {
            data: this.regulatedTableData.map(rd => parseFloat(rd.months_lt_6)),
            color: '#A7CA77',
            negativeColor: '#333333',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []
      //year Headers
      let yearHeaders = []
      if (this.canShowProducts) yearHeaders.push({ value: '', colSpan: 2 })
      else yearHeaders.push({ value: '', colSpan: 1 })
      yearHeaders.push({ value: 'Release Age Groups', colSpan: 6 })
      //Headers
      let headers = [
        'Rank',
        'Vendor Name',
        { value: '< 3 months', format: { alignment: 'right' } },
        {
          value: '3 - 6 months',
          format: { alignment: 'right' }
        },
        {
          value: '6 - 12 months',
          format: { alignment: 'right' }
        },
        {
          value: '12 - 18 months',
          format: { alignment: 'right' }
        },
        {
          value: '18 - 24 months',
          format: { alignment: 'right' }
        },
        {
          value: '24+ months',
          format: { alignment: 'right' }
        }
      ]
      if (this.canShowProducts) headers.splice(2, 0, 'Product Name')
      downloadReportRows.push(headers)
      const combinedData = [...this.regulatedTableData, this.reportTotal]
      //Report data
      combinedData.map((row, index) => {
        let data = []
        data.push(index === combinedData.length - 1 ? '' : index + 1)
        data.push(row['vendor_name'])
        if (this.canShowProducts) {
          data.push(row['product_name'] ? row['product_name'] : '')
        }
        data.push({
          value: row['months_lt_3'],
          format: this.dataFormat['months_lt_3']
        })
        data.push({
          value: row['months_3_6'],
          format: this.dataFormat['months_3_6']
        })
        data.push({
          value: row['months_6_12'],
          format: this.dataFormat['months_6_12']
        })
        data.push({
          value: row['months_12_18'],
          format: this.dataFormat['months_12_18']
        })
        data.push({
          value: row['months_18_24'],
          format: this.dataFormat['months_18_24']
        })
        data.push({
          value: row['months_gt_24'],
          format: this.dataFormat['months_gt_24']
        })

        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name: 'Retail Mobile Banking Vendor Analytics - Version Age Groups',
        subTitle: this.reportSubTitle,
        yearHeaders: yearHeaders,
        data: downloadReportRows
      })
    }
  }
}
</script>
