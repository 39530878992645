var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-gutters market-report-container"},[_c('div',{},[_c('table',{staticClass:"table table-striped"},[(_vm.reportData.length)?_c('thead',[_c('tr',[_c('th',{attrs:{"width":"20%"},on:{"click":function($event){return _vm.sortByColumn('name')}}},[_vm._v(" Feature "),_c('span',[_c('i',{class:['fa fa-regular sort-icon', _vm.getSortIconClass('name')]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"12%"},on:{"click":function($event){return _vm.sortByColumn('previous_pct')}}},[_vm._v(" Previous % w/ Feature "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('previous_pct')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"12%"},on:{"click":function($event){return _vm.sortByColumn('current_pct')}}},[_vm._v(" Current % w/ Feature "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('current_pct')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"10%"},on:{"click":function($event){return _vm.sortByColumn('added_feature')}}},[_vm._v(" # Added Feature "),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('added_feature')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"46%"}},[_vm._v(" FIs Adding Feature # ")])])]):_vm._e(),(_vm.reportData.length)?_c('tbody',{ref:"reportTableBody"},[_vm._l((_vm.reportData),function(data,key){return _c('tr',{key:key},[_c('td',{staticClass:"name-column font-weight-bold"},[_vm._v(" "+_vm._s(data.name)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.previous_pct,_vm.dataFormat['previous_pct']))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.current_pct,_vm.dataFormat['current_pct']))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(data.added_feature,_vm.dataFormat['added_feature']))+" ")]),_c('td',{staticClass:"p-0",attrs:{"rowspan":_vm.reportData.length + 1}},[(_vm.showChart)?_c('div',{ref:"reportChartContainer",refInFor:true,staticClass:"report-chart-container"},[_c('div',{attrs:{"id":"report-chart-iafl"}})]):_vm._e()])])}),_vm._m(0)],2):_c('tbody',[_vm._m(1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"report-footer"},[_c('td',{attrs:{"colspan":"4"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"6"}},[_c('div',{staticClass:"text-center text-muted"},[_vm._v("No Records found")])])])}]

export { render, staticRenderFns }