<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="regulatedTableData.length">
          <tr>
            <th @click="sortByColumn('name')" width="15%">
              FI Asset Group
              <span>
                <i
                  :class="['fa fa-regular sort-icon', getSortIconClass('name')]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('bank_customer_enrollment')"
              class="text-right"
              width="15%"
            >
              Bank Enrolled Customers to Accounts
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('bank_customer_enrollment')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('cu_customer_enrollment')"
              class="text-right"
              width="15%"
            >
              CU Enrolled Customers to Accounts
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('cu_customer_enrollment')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('diff_customer_enrollment')"
              class="text-right"
              width="15%"
            >
              Diff Enrolled Customers to Accounts
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('diff_customer_enrollment')
                  ]"
                >
                </i>
              </span>
            </th>
            <th class="text-right" width="40%">
              <div class="">
                <div class="text-left">
                  <span class="bar-legend lifetime-installs"></span>
                  <span class="px-2">Banks</span>
                </div>
                <div class="text-left">
                  <span class="bar-legend enrollment"></span>
                  <span class="px-2">Credit Unions</span>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="regulatedTableData.length">
          <tr
            v-for="(data, asset_group) in regulatedTableData"
            :key="asset_group"
          >
            <td class="name-column font-weight-bold">
              {{ data.name }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.bank_customer_enrollment)
                  | formatValue(dataFormat.bank_customer_enrollment)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.cu_customer_enrollment)
                  | formatValue(dataFormat.cu_customer_enrollment)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.diff_customer_enrollment)
                  | formatValue(dataFormat.diff_customer_enrollment)
              }}
            </td>

            <td
              class="p-0"
              :rowspan="reportData.length"
              v-if="asset_group === 0"
            >
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-iaet"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td class="">Grand Total</td>
            <td class="text-right">
              {{
                grandTotal.bank_customer_enrollment
                  | formatValue(dataFormat.bank_customer_enrollment)
              }}
            </td>
            <td class="text-right">
              {{
                grandTotal.cu_customer_enrollment
                  | formatValue(dataFormat.cu_customer_enrollment)
              }}
            </td>
            <td class="text-right">
              {{
                grandTotal.diff_customer_enrollment
                  | formatValue(dataFormat.diff_customer_enrollment)
              }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'IAEnrollmentType',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        name: ['none', 'asset_group'],
        bank_customer_enrollment: ['none', 'float'],
        cu_customer_enrollment: ['none', 'float'],
        diff_customer_enrollment: ['none', 'float']
      }
    }
  },
  computed: {
    ...mapGetters('DigitalChannels', ['reportSubTitle'])
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-iaet'
        ) {
          chart.destroy()
        }
      })
      const dataFormat = this.dataFormat

      Highcharts.chart('report-chart-iaet', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: true,
            autoRotation: false,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(
                this.value,
                dataFormat.bank_customer_enrollment
              )
            }
          },
          title: {
            text: null
          }
        },
        series: [
          {
            data: this.regulatedTableData.map(rd =>
              parseFloat(rd.bank_customer_enrollment)
            ),
            color: '#A7CA77',
            pointPlacement: this.pointPlacementValue()
          },
          {
            data: this.regulatedTableData.map(rd =>
              parseFloat(rd.cu_customer_enrollment)
            ),
            color: '#333333',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'FI Asset Group',
        'Bank Enrolled Customers to Accounts',
        'CU Enrolled Customers to Accounts',
        'Diff Enrolled Customers to Accounts'
      ]

      downloadReportRows.push(headers)
      const combinedData = [...this.regulatedTableData, this.grandTotal]
      //Report data +Grand Total
      combinedData.map(row => {
        let data = []
        data.push(row['name'])
        data.push({
          value: row['bank_customer_enrollment'],
          format: this.dataFormat['bank_customer_enrollment']
        })
        data.push({
          value: row['cu_customer_enrollment'],
          format: this.dataFormat['cu_customer_enrollment']
        })
        data.push({
          value: row['diff_customer_enrollment'],
          format: this.dataFormat['diff_customer_enrollment']
        })

        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name: 'Retail Mobile Banking Institution Analytics - Enrollment Type',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
