<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="reportTableData.length">
          <tr>
            <th @click="sortByColumn('name')" width="15%">
              FI Asset Group
              <span>
                <i
                  :class="['fa fa-regular sort-icon', getSortIconClass('name')]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('total_fi')"
              class="text-right"
              width="10%"
            >
              FI Count
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('total_fi')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('fi_w_app')"
              class="text-right"
              width="15%"
            >
              FIs w/ App
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('fi_w_app')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('fi_w_app_pct')"
              class="text-right"
              width="15%"
            >
              FIs w/ App %
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('fi_w_app_pct')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('avg_mb_age')"
              class="text-right"
              width="15%"
            >
              Avg MB Age
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('avg_mb_age')
                  ]"
                >
                </i>
              </span>
            </th>
            <th class="text-right" width="30%">
              FI Adoption %
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="reportTableData.length">
          <tr v-for="(data, asset_group) in reportTableData" :key="asset_group">
            <td class="name-column font-weight-bold">
              {{ data.name }}
            </td>
            <td class="text-right">
              {{ data.total_fi | formatValue(dataFormat.total_fi) }}
            </td>
            <td class="text-right">
              {{ data.fi_w_app | formatValue(dataFormat.fi_w_app) }}
            </td>
            <td class="text-right">
              {{ data.fi_w_app_pct | formatValue(dataFormat.fi_w_app_pct) }}
            </td>
            <td class="text-right">
              {{ data.avg_mb_age | formatValue(dataFormat.avg_mb_age) }}
            </td>

            <td class="p-0" :rowspan="reportData.length">
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-iaa"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td>Grand Total</td>
            <td class="text-right">
              {{ reportTotal.total_fi | formatValue(dataFormat.total_fi) }}
            </td>
            <td class="text-right">
              {{ reportTotal.fi_w_app | formatValue(dataFormat.fi_w_app) }}
            </td>
            <td class="text-right">
              {{
                reportTotal.fi_w_app_pct | formatValue(dataFormat.fi_w_app_pct)
              }}
            </td>
            <td class="text-right">
              {{ reportTotal.avg_mb_age | formatValue(dataFormat.avg_mb_age) }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
// global
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'IAAdoption',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        name: ['none', 'asset_group'],
        total_fi: ['none', 'float'],
        fi_w_app: ['none', 'float'],
        fi_w_app_pct: ['none', 'float'],
        avg_mb_age: ['none', 'float']
      }
    }
  },
  computed: {
    ...mapGetters('DigitalChannels', ['reportSubTitle']),
    reportTableData() {
      return this.reportData.filter(data => data.name !== 'Grand Total')
    },

    reportTotal() {
      return this.reportData.find(data => data.name === 'Grand Total')
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),
    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-iaa'
        ) {
          chart.destroy()
        }
      })
      const dataFormat = this.dataFormat
      Highcharts.chart('report-chart-iaa', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(
                this.value,
                dataFormat.fi_w_app_pct
              )
            }
          },
          title: {
            text: null
          }
        },
        series: [
          {
            name: '',
            data: this.reportTableData.map(rd => parseFloat(rd.fi_w_app_pct)),
            color: '#A7CA77',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'FI Asset Group',
        'FI Count',
        'FIs w/ App',
        'FIs w/ App %',
        'Avg MB Age'
      ]

      downloadReportRows.push(headers)
      const combinedData = [...this.reportTableData, this.reportTotal]
      //Report data
      combinedData.map(row => {
        let data = []
        data.push(row['name'])
        data.push({
          value: row['total_fi'],
          format: this.dataFormat.total_fi
        })
        data.push({
          value: row['fi_w_app'],
          format: this.dataFormat.fi_w_app
        })
        data.push({
          value: row['fi_w_app_pct'],
          format: this.dataFormat['fi_w_app_pct']
        })
        data.push({
          value: row['avg_mb_age'],
          format: this.dataFormat['avg_mb_age']
        })

        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name: 'Retail Mobile Banking Institution Analytics - Adoption',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
