var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-gutters market-report-container"},[_c('div',{},[_c('table',{staticClass:"table table-striped"},[(_vm.reportData.length)?_c('thead',[_c('tr',[_c('th',{attrs:{"width":_vm.canShowProducts ? '5%' : '10%'}},[_c('div',[_vm._v("Rank")])]),_c('th',{attrs:{"width":_vm.canShowProducts ? '25%' : '30%'},on:{"click":function($event){return _vm.sortByColumn('vendor_name')}}},[_c('span',[_vm._v("Vendor Name")]),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('vendor_name')
                ]})])]),(_vm.canShowProducts)?_c('th',{attrs:{"width":"20%"},on:{"click":function($event){return _vm.sortByColumn('product_name')}}},[_c('span',[_vm._v("Product Name")]),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('product_name')
                ]})])]):_vm._e(),_c('th',{staticClass:"text-right",attrs:{"width":_vm.canShowProducts ? '10%' : '15%'},on:{"click":function($event){return _vm.sortByColumn('market_total')}}},[_c('span',[_vm._v("Market Total")]),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('market_total')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":_vm.canShowProducts ? '10%' : '15%'},on:{"click":function($event){return _vm.sortByColumn('percentage_of_market')}}},[_c('span',[_vm._v("% of Market")]),_c('span',[_c('i',{class:[
                  'fa fa-regular sort-icon',
                  _vm.getSortIconClass('percentage_of_market')
                ]})])]),_c('th',{staticClass:"text-right",attrs:{"width":"30%"}},[_vm._v(" % of Total Market ")])])]):_vm._e(),(_vm.regulatedTableData.length)?_c('tbody',{ref:"reportTableBody"},[_vm._l((_vm.regulatedTableData),function(data,rank){return _c('tr',{key:rank},[_c('td',{},[_vm._v(" "+_vm._s(rank + 1)+" ")]),_c('td',{staticClass:"name-column",attrs:{"title":data.vendor_name}},[_vm._v(" "+_vm._s(data.vendor_name)+" ")]),(_vm.canShowProducts)?_c('td',{staticClass:"name-column",attrs:{"title":data.product_name}},[_vm._v(" "+_vm._s(data.product_name)+" ")]):_vm._e(),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(parseFloat(data.market_total),_vm.dataFormat.market_total))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(parseFloat(data.percentage_of_market),_vm.dataFormat.percentage_of_market))+" ")]),(rank === 0)?_c('td',{staticClass:"p-0",attrs:{"rowspan":_vm.regulatedTableData.length + 1}},[(_vm.showChart)?_c('div',{ref:"reportChartContainer",refInFor:true,staticClass:"report-chart-container"},[_c('div',{ref:"reportChart",refInFor:true,attrs:{"id":"report-chart-vams"}})]):_vm._e()]):_vm._e()])}),_c('tr',{staticClass:"report-footer"},[_c('td',{attrs:{"colspan":_vm.canShowProducts ? 3 : 2}},[_vm._v("Grand Total")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(parseFloat(_vm.reportTotal.market_total),_vm.dataFormat.market_total))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatValue")(parseFloat(_vm.reportTotal.percentage_of_market),_vm.dataFormat.percentage_of_market))+" ")])])],2):_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":_vm.canShowProducts ? 6 : 5}},[_c('div',{staticClass:"text-center text-muted"},[_vm._v("No Records found")])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }