<template>
  <div class="px-2">
    <div>
      <div>
        <div class="market-report-header">
          <div class="row">
            <div class="col-sm-12">
              <div class="">
                <div class="d-flex align-items-baseline report-title">
                  <div class="pr-2" v-if="selectedReport">
                    {{ selectedReport.report_type }} -
                  </div>
                  <div>
                    <multiselect
                      track-by="id"
                      label="display_name"
                      :allowEmpty="false"
                      :showLabels="false"
                      :options="groupedReports"
                      group-label="report_type"
                      group-values="reports"
                      :group-select="false"
                      :searchable="false"
                      class="min-width-20 cursor-pointer dc-report-select"
                      v-model="selectedReport"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        <div class="d-flex">
                          <div class="option-text">
                            {{ option.name }}
                          </div>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 report-subtitle ">
              <div class="">
                {{ this.reportSubTitle }}
                <sup
                  ><i
                    class="icon-info icons font-weight-bold cursor-pointer"
                    id="report-overview"
                  ></i
                ></sup>
              </div>
            </div>
            <div
              class="col-sm-6 text-right selected-filters-message"
              v-if="this.selectedFiltersMessage.trim() !== ``"
            >
              <span class="font-weight-bold text-muted">
                <i class="fa fa-filter" aria-hidden="true"></i>
                Filters:</span
              >
              <span class="pl-2">{{ this.selectedFiltersMessage }}</span>
            </div>
          </div>
        </div>

        <b-popover
          triggers="hover"
          target="report-overview"
          :title="selectedReport ? selectedReport.name : 'Loading'"
        >
          {{ overviewText ? overviewText : 'Loading ...' }}
        </b-popover>

        <hr class="header-divider" />

        <div v-if="loadingReport">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="15"></content-placeholders-text>
          </content-placeholders>
        </div>
        <div v-else>
          <div v-if="selectedReport">
            <component
              v-bind:is="reportComponent()"
              :ref="this.selectedReport.name.replace(/\W/g, '')"
              :reportData="reportData"
              :reportTotal="reportTotal"
              :dataFormat="dataFormat"
              :reportHeaders="reportHeaders"
              :reportOtherData="reportOtherData"
              @overviewText="updateOverviewText"
            ></component>
            <div class="dc-pdf-header">
              <p class="">{{ overviewText }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
// global
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
// ui components
import Multiselect from 'vue-multiselect'
import VAMarketShare from '../components/VAMarketShare'
import VAMarketShareChange from '../components/VAMarketShareChange'
import VAMarketShareAccretion from '../components/VAMarketShareAccretion'
import VAChurnRate from '../components/VAChurnRate'
import VACustomerEnrollment from '../components/VACustomerEnrollment'
import VAEnrollmentFIType from '../components/VAEnrollmentFIType'
import VAEnrollmentGrowth from '../components/VAEnrollmentGrowth'
import VASatisfaction from '../components/VASatisfaction'
import VASatisfactionFIType from '../components/VASatisfactionFIType'
import VASatisfactionChange from '../components/VASatisfactionChange'
//import VASatRatingsInstallsFIType from '../components/VASatRatingsInstallsFIType'
import VAWinLossImpact from '../components/VAWinLossImpact'
import VAChurnFIType from '../components/VAChurnFIType'
import IASatisfaction from '../components/IASatisfaction'
import IASatisfactionType from '../components/IASatisfactionType'
import IASatisfactionChange from '../components/IASatisfactionChange'
import IACustomerEnrollment from '../components/IACustomerEnrollment'
import IAEnrollmentAge from '../components/IAEnrollmentAge'
import IAEnrollmentType from '../components/IAEnrollmentType'
import IAEnrollmentTrend from '../components/IAEnrollmentTrend'
import IAEnrollmentGrowth from '../components/IAEnrollmentGrowth'
import IAEnrollmentTrendType from '../components/IAEnrollmentTrendType'
import IASatisfactionTrend from '../components/IASatisfactionTrend'
import IASatisfactionTrendType from '../components/IASatisfactionTrendType'
import IAFeatureLeaderboard from '../components/IAFeatureLeaderboard'
import IAFeatureLeaderboardLift from '../components/IAFeatureLeaderboardLift'
import IAFeatureSelectEnrollLift from '../components/IAFeatureSelectEnrollLift'
import IAFeatureSelectSatLift from '../components/IAFeatureSelectSatLift'
import IAFeatures from '../components/IAFeatures'
import IAFeatureLift from '../components/IAFeatureLift'
import IAFeatureSegments from '../components/IAFeatureSegments'
import IAFeatureSegEnrollment from '../components/IAFeatureSegEnrollment'
import IAFeatureSegEnrollmentAssets from '../components/IAFeatureSegEnrollmentAssets'
import IAFeatureSegEnrollmentAge from '../components/IAFeatureSegEnrollmentAge'
import IAFeatureSegSatisfaction from '../components/IAFeatureSegSatisfaction'
import IAFeatureSegSatAssets from '../components/IAFeatureSegSatAssets'
import IAFeatureSatLift from '../components/IAFeatureSatLift'
import IAAgeFIType from '../components/IAAgeFIType'
import VAClientSizeFirmographics from '../components/VAClientSizeFirmographics'
import VAClientFirmographicDetails from '../components/VAClientFirmographicDetails'
import VAFirmographicsChange from '../components/VAFirmographicsChange'
import VAVersionCount from '../components/VAVersionCount'
import VAIncrementalSat from '../components/VAIncrementalSat'
import VASatisfactionIndex from '../components/VASatisfactionIndex'
import VAAppRelease from '../components/VAAppRelease'
import VAAppVersionAge from '../components/VAAppVersionAge'
import VAVersionAgeGroups from '../components/VAVersionAgeGroups'
import VAChurnEnrollment from '../components/VAChurnEnrollment'
import IAAdoption from '../components/IAAdoption'
import IAAdoptionFIType from '../components/IAAdoptionFIType'
import IAAdoptionChangeType from '../components/IAAdoptionChangeType'
import IAAdoptionTrend from '../components/IAAdoptionTrend'
import IAAdoptionTrendType from '../components/IAAdoptionTrendType'
import IAChurnRate from '../components/IAChurnRate'
import IAChurnRateType from '../components/IAChurnRateType'
import OlbVAMarketShare from '../components/OlbVAMarketShare'
import OlbVAEnrollment from '../components/OlbVAEnrollment'
import OlbVAClientFirmographics from '../components/OlbVAClientFirmographics'
import OlbIAAdoption from '../components/OlbIAAdoption'
import OlbIAEnrollment from '../components/OlbIAEnrollment'
// mixins
import PageSettings from '@/mixins/PageSettings'
// utilities
import _ from 'lodash'

import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'DigitalChannels/getField',
  mutationType: 'DigitalChannels/updateField'
})

export default {
  name: 'DigitalChannelsReport',
  components: {
    Multiselect,
    VAMarketShare,
    VAMarketShareChange,
    VAMarketShareAccretion,
    VAChurnRate,
    VACustomerEnrollment,
    VAEnrollmentGrowth,
    VAEnrollmentFIType,
    VASatisfaction,
    VASatisfactionFIType,
    VASatisfactionChange,
    //VASatRatingsInstallsFIType,
    VAWinLossImpact,
    VAChurnFIType,
    IASatisfaction,
    IASatisfactionType,
    IASatisfactionChange,
    IACustomerEnrollment,
    IAEnrollmentAge,
    IAEnrollmentType,
    IAEnrollmentTrend,
    IAEnrollmentGrowth,
    IAEnrollmentTrendType,
    IASatisfactionTrend,
    IASatisfactionTrendType,
    IAFeatureLeaderboard,
    IAFeatureLeaderboardLift,
    IAFeatureSelectEnrollLift,
    IAFeatureSelectSatLift,
    IAFeatures,
    IAFeatureLift,
    IAFeatureSegments,
    IAFeatureSegEnrollment,
    IAFeatureSegEnrollmentAssets,
    IAFeatureSegEnrollmentAge,
    IAFeatureSegSatisfaction,
    IAFeatureSegSatAssets,
    IAFeatureSatLift,
    IAAgeFIType,
    VAClientSizeFirmographics,
    VAClientFirmographicDetails,
    VAFirmographicsChange,
    VAVersionCount,
    VAIncrementalSat,
    VASatisfactionIndex,
    VAAppVersionAge,
    VAAppRelease,
    VAVersionAgeGroups,
    VAChurnEnrollment,
    IAAdoption,
    IAAdoptionFIType,
    IAAdoptionChangeType,
    IAAdoptionTrend,
    IAAdoptionTrendType,
    IAChurnRate,
    IAChurnRateType,
    OlbVAMarketShare,
    OlbVAEnrollment,
    OlbVAClientFirmographics,
    OlbIAAdoption,
    OlbIAEnrollment
  },
  mixins: [PageSettings],
  data() {
    return {
      overviewText: null
    }
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters('DigitalChannels', [
      'selectedReportFilters',
      'reportSubTitle',
      'selectedFiltersMessage'
    ]),
    ...mapState('DigitalChannels', [
      'loadingReport',
      'reportData',
      'dataFormat',
      'reportTotal',
      'reportHeaders',
      'reportOtherData',
      'reportTypes',
      'currentPeriod',
      'previousPeriod',
      'marketMeasure',
      'olbMarketMeasure',
      'topVendors',
      'topVendorsMax',
      'showAllVendors',
      'topFeatures',
      'topFeaturesMax',
      'showMbProducts',
      'fiType',
      'name',
      'fiAssetGroup',
      'mbAgeGroup',
      'featureMbAgeGroup',
      'vendorBranded',
      'appType',
      'knownVendor',
      'multiSelectFilterChanged',
      'beginningPeriod',
      'dateLevel',
      'selectedReportModule',
      'clientSizeMetric',
      'olbClientSizeMetric',
      'satisfactionMeasure'
    ]),
    ...mapFields(['selectedReport']),
    groupedReports() {
      this.reportTypes.forEach(report => {
        report.display_name =
          this.getReportTypeText(report.report_type) + report.name
      })
      let groupedReportsByModule = _.groupBy(
        this.reportTypes,
        rt => rt.report_module
      )

      let groupedReports = _(groupedReportsByModule[this.selectedReportModule])
        .sortBy(rt => rt.display_order)
        .groupBy(rt => rt.report_type)
        .value()
      //console.log(groupedReports)
      if (
        this.selectedReport &&
        this.selectedReport.report_module !== this.selectedReportModule
      )
        this.setSelectedReport(
          groupedReports[Object.keys(groupedReports)[0]][0]
        )
      return Object.keys(groupedReports).map(type => {
        return {
          report_type: type,
          reports: groupedReports[type]
        }
      })
    }
  },
  methods: {
    ...mapActions('DigitalChannels', ['loadReportData']),
    ...mapMutations('DigitalChannels', [
      'setSelectedReportFilters',
      'setReportLoadingStatus',
      'setTopVendorsMax',
      'resetTopVendors',
      'updateTopVendors',
      'setMultiSelectFilterChanged',
      'setSelectedReport'
    ]),
    getReportTypeText(report_type) {
      return report_type === 'Vendor Analytics' ? 'VA ' : 'IA '
    },
    trackReportView() {
      this.$ahoy.track('digital_channels', {
        report_module:
          this.selectedReport.report_module || 'Retail Mobile Banking',
        report_type: this.selectedReport.report_type || 'Vendor Analytics',
        report_name: this.selectedReport.name || 'Market Share'
      })
    },
    reportComponent() {
      let reportModule =
        this.selectedReport &&
        this.selectedReport.report_module === 'Retail Online Banking'
          ? 'Olb'
          : ''
      let reportType =
        this.selectedReport &&
        this.selectedReport.report_type === 'Vendor Analytics'
          ? 'VA'
          : 'IA'

      return (
        reportModule + reportType + this.selectedReport.name.replace(/\W/g, '')
      )
    },
    updateOverviewText(text) {
      this.overviewText = text || null
    }
  },
  watch: {
    selectedReport: {
      immediate: true,
      handler: function(newVal) {
        if (newVal) {
          this.trackReportView()
          this.loadReportData()
        }
      }
    },
    currentPeriod: {
      handler: function() {
        this.loadReportData()
      }
    },
    previousPeriod: {
      handler: function() {
        this.loadReportData()
      }
    },
    marketMeasure: {
      handler: function() {
        this.loadReportData()
      }
    },
    olbMarketMeasure: {
      handler: function() {
        this.loadReportData()
      }
    },
    showMbProducts: {
      handler: function() {
        this.loadReportData()
      }
    },
    fiType: {
      handler: function() {
        this.loadReportData()
      }
    },
    name: {
      handler: function() {
        this.loadReportData()
      }
    },
    multiSelectFilterChanged: {
      handler: function() {
        if (this.multiSelectFilterChanged) {
          this.loadReportData()
          this.setMultiSelectFilterChanged(false)
        }
      }
    },
    vendorBranded: {
      handler: function() {
        this.loadReportData()
      }
    },
    appType: {
      handler: function() {
        this.loadReportData()
      }
    },
    knownVendor: {
      handler: function() {
        this.loadReportData()
      }
    },
    beginningPeriod: {
      handler: function() {
        this.loadReportData()
      }
    },
    dateLevel: {
      handler: function() {
        this.loadReportData()
      }
    },
    clientSizeMetric: {
      handler: function() {
        this.loadReportData()
      }
    },
    olbClientSizeMetric: {
      handler: function() {
        this.loadReportData()
      }
    },
    satisfactionMeasure: {
      handler: function() {
        this.loadReportData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.report-subtitle {
  padding-right: 0;
  flex: 0 0 70%;
  max-width: 70%;
}
.selected-filters-message {
  padding-right: 0;
  padding-left: 0;
  flex: 0 0 30%;
  max-width: 30%;
}
.dc-pdf-header {
  display: none;
}
</style>
<style lang="scss">
@media print {
  .digital-channels-report {
    .dc-pdf-header {
      display: block;
    }
  }
}
</style>
