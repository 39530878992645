<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="reportData.length">
          <tr>
            <th width="30%"></th>
            <th
              @click="sortByColumn('satisfaction_rating')"
              class="text-right"
              width="20%"
            >
              Satisfaction Rating
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('satisfaction_rating')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('enrollment_pct')"
              class="text-right"
              width="20%"
            >
              Enrollment %
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('enrollment_pct')
                  ]"
                >
                </i>
              </span>
            </th>
            <th class="text-right" width="30%">
              {{ enrollmentText }}
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="reportData.length">
          <tr v-for="(data, index) in reportData" :key="index">
            <td class="name-column font-weight-bold">
              {{ data.title }}
            </td>

            <td class="text-right">
              {{
                parseFloat(data.satisfaction_rating)
                  | formatValue(dataFormat.satisfaction_rating)
              }}
            </td>
            <td class="text-right">
              {{
                parseFloat(data.enrollment_pct)
                  | formatValue(dataFormat.enrollment_pct)
              }}
            </td>

            <td class="p-0" :rowspan="reportData.length + 1">
              <div
                class="report-chart-container"
                ref="reportChartContainer"
                v-if="showChart"
              >
                <div id="report-chart-vache" ref="reportChart"></div>
              </div>
            </td>
          </tr>
          <tr class="report-footer">
            <td colspan="3"></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'VAChurnEnrollment',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        satisfaction_rating: ['none', 'float'],
        enrollment_pct: ['none', 'float']
      }
    }
  },
  computed: {
    ...mapState('DigitalChannels', ['fiType']),
    ...mapGetters('DigitalChannels', ['reportSubTitle']),
    enrollmentText() {
      if (this.fiType === 'Credit Unions')
        return 'Enrolled Customers To Members'
      else return 'Enrolled Customers To Accounts'
    }
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },
  destroyed() {
    this.clearListener()
  },
  updated() {
    this.prepareDownloadReport()
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),

    drawChart() {
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-vache'
        ) {
          chart.destroy()
        }
      })

      Highcharts.chart('report-chart-vache', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0,
            dataLabels: {
              enabled: false
            }
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(this.value, 'P0')
            }
          },
          title: {
            text: null
          }
        },
        series: [
          {
            data: this.reportData.map(rd => parseFloat(rd.enrollment_pct)),
            color: '#A7CA77',
            negativeColor: '#333333',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = ['', 'Satisfaction Rating', 'Enrollment %']

      downloadReportRows.push(headers)
      this.reportData.map(row => {
        let data = []
        data.push(row['title'])

        data.push({
          value: row['satisfaction_rating'],
          format: this.dataFormat['satisfaction_rating']
        })
        data.push({
          value: row['enrollment_pct'],
          format: this.dataFormat['enrollment_pct']
        })

        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name: 'Retail Mobile Banking Vendor Analytics - Churn Enrollment',
        subTitle: this.reportSubTitle,
        yearHeaders: [],
        data: downloadReportRows
      })
    }
  }
}
</script>
