<template>
  <div class="no-gutters market-report-container">
    <div class="">
      <table class="table table-striped">
        <thead v-if="regulatedTableData.length">
          <tr>
            <td></td>
            <td v-if="canShowProducts"></td>
            <td
              colspan="2"
              class="left-border text-center no-bottom-padding"
              v-if="canShowBankDetails"
            >
              <b>Banks</b>
            </td>
            <td
              colspan="2"
              class="left-border text-center no-bottom-padding "
              v-if="canShowCuDetails"
            >
              <b>Credit Unions</b>
            </td>
            <td colspan="2" class="left-border text-center no-bottom-padding ">
              <b>Grand Total</b>
            </td>
          </tr>
          <tr>
            <th @click="sortByColumn('vendor_name')" width="10%">
              Vendor Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('vendor_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('product_name')"
              width="10%"
              v-if="canShowProducts"
            >
              Product Name
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('product_name')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('bank_market_total')"
              class="text-right left-border "
              width="15%"
              v-if="canShowBankDetails"
            >
              Market Total
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('bank_market_total')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('bank_client_avg')"
              class="text-right"
              width="15%"
              v-if="canShowBankDetails"
            >
              Client Average
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('bank_client_avg')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('cu_market_total')"
              class="text-right left-border "
              width="15%"
              v-if="canShowCuDetails"
            >
              Market Total
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('cu_market_total')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('cu_client_avg')"
              class="text-right"
              width="15%"
              v-if="canShowCuDetails"
            >
              Client Average
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('cu_client_avg')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('market_total')"
              class="text-right left-border "
              width="15%"
            >
              Market Total
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('market_total')
                  ]"
                >
                </i>
              </span>
            </th>
            <th
              @click="sortByColumn('client_avg')"
              class="text-right"
              width="15%"
            >
              Client Average
              <span>
                <i
                  :class="[
                    'fa fa-regular sort-icon',
                    getSortIconClass('client_avg')
                  ]"
                >
                </i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody ref="reportTableBody" v-if="regulatedTableData.length">
          <tr v-for="(data, rank) in regulatedTableData" :key="rank">
            <td class="name-column" :title="data.vendor">
              {{ data.vendor_name }}
            </td>
            <td
              class="name-column"
              :title="data.product_name"
              v-if="canShowProducts"
            >
              {{ data.product_name }}
            </td>
            <td class="text-right left-border " v-if="canShowBankDetails">
              {{
                data.bank_market_total
                  | formatValue(dataFormat.bank_market_total)
              }}
            </td>
            <td class="text-right" v-if="canShowBankDetails">
              {{
                data.bank_client_avg | formatValue(dataFormat.bank_client_avg)
              }}
            </td>
            <td class="text-right left-border " v-if="canShowCuDetails">
              {{
                data.cu_market_total | formatValue(dataFormat.cu_market_total)
              }}
            </td>
            <td class="text-right " v-if="canShowCuDetails">
              {{ data.cu_client_avg | formatValue(dataFormat.cu_client_avg) }}
            </td>
            <td class="text-right left-border ">
              {{ data.market_total | formatValue(dataFormat.market_total) }}
            </td>
            <td class="text-right ">
              {{ data.client_avg | formatValue(dataFormat.client_avg) }}
            </td>
          </tr>
          <tr class="report-footer">
            <td :colspan="canShowProducts ? 2 : 1">Grand Total</td>
            <td class="text-right left-border " v-if="canShowBankDetails">
              {{
                reportTotal.bank_market_total
                  | formatValue(dataFormat.bank_market_total)
              }}
            </td>
            <td class="text-right" v-if="canShowBankDetails">
              {{
                reportTotal.bank_client_avg
                  | formatValue(dataFormat.bank_client_avg)
              }}
            </td>
            <td class="text-right left-border " v-if="canShowCuDetails">
              {{
                reportTotal.cu_market_total
                  | formatValue(dataFormat.cu_market_total)
              }}
            </td>
            <td class="text-right" v-if="canShowCuDetails">
              {{
                reportTotal.cu_client_avg
                  | formatValue(dataFormat.cu_client_avg)
              }}
            </td>
            <td class="text-right left-border ">
              {{
                reportTotal.market_total | formatValue(dataFormat.market_total)
              }}
            </td>
            <td class="text-right">
              {{ reportTotal.client_avg | formatValue(dataFormat.client_avg) }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">
              <div class="text-center text-muted">No Records found</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
// mixins
import ReportShow from '../mixins/report.show'

export default {
  name: 'VAClientSizeFirmographics',
  components: {},
  mixins: [ReportShow],
  props: {
    reportData: {
      required: true,
      default: []
    },
    dataFormat: {
      required: true,
      default: []
    },
    reportOtherData: {
      required: true,
      default: null
    },
    reportTotal: {
      required: true,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showChart: true,
      sortOrder: {
        vendor_name: ['none', 'string'],
        product_name: ['none', 'string'],
        bank_market_total: ['none', 'float'],
        bank_client_avg: ['none', 'float'],
        cu_market_total: ['none', 'float'],
        cu_client_avg: ['none', 'float'],
        market_total: ['none', 'float'],
        client_avg: ['none', 'float']
      }
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
  },

  updated() {
    this.prepareDownloadReport()
  },
  computed: {
    ...mapState('DigitalChannels', ['topVendors', 'topVendorsMax', 'fiType']),
    ...mapGetters('DigitalChannels', ['canShowProducts', 'reportSubTitle']),
    regulatedTableData() {
      let regulatedData = this.reportData ? [...this.reportData] : []

      if (this.reportOtherData && this.reportData.length < this.topVendorsMax) {
        regulatedData.push(this.reportOtherData)
      }

      return regulatedData
    },
    canShowBankDetails() {
      if (this.fiType === 'Credit Unions') return false
      else return true
    },
    canShowCuDetails() {
      if (this.fiType === 'Banks') return false
      else return true
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', ['setDownloadDCReportData']),

    prepareDownloadReport() {
      let downloadReportRows = []
      //year Headers
      let yearHeaders = []
      if (this.canShowProducts) yearHeaders.push({ value: '', colSpan: 1 })
      if (this.canShowBankDetails)
        yearHeaders.push({ value: 'Banks', colSpan: 2 })
      if (this.canShowCuDetails)
        yearHeaders.push({ value: 'Credit Unions', colSpan: 2 })
      yearHeaders.push({ value: 'Grand Total', colSpan: 2 })
      //Headers
      let headers = [
        'Vendor Name',
        { value: 'Market Total', format: { alignment: 'right' } },
        { value: 'Client Average', format: { alignment: 'right' } }
      ]
      if (this.canShowBankDetails) {
        headers.push({ value: 'Market Total', format: { alignment: 'right' } })
        headers.push({
          value: 'Client Average',
          format: { alignment: 'right' }
        })
      }
      if (this.canShowCuDetails) {
        headers.push({ value: 'Market Total', format: { alignment: 'right' } })
        headers.push({
          value: 'Client Average',
          format: { alignment: 'right' }
        })
      }
      if (this.canShowProducts) headers.splice(1, 0, 'Product Name')
      downloadReportRows.push(headers)
      const combinedData = [...this.regulatedTableData, this.reportTotal]

      //Report data
      combinedData.map(row => {
        let data = []

        data.push(row['vendor_name'])
        if (this.canShowProducts) {
          data.push(row['product_name'] ? row['product_name'] : '')
        }
        if (this.canShowBankDetails) {
          data.push({
            value: row['bank_market_total'],
            format: this.dataFormat['bank_market_total']
          })
          data.push({
            value: row['bank_client_avg'],
            format: this.dataFormat['bank_client_avg']
          })
        }
        if (this.canShowCuDetails) {
          data.push({
            value: row['cu_market_total'],
            format: this.dataFormat['cu_market_total']
          })
          data.push({
            value: row['cu_client_avg'],
            format: this.dataFormat['cu_client_avg']
          })
        }
        data.push({
          value: row['market_total'],
          format: this.dataFormat['market_total']
        })
        data.push({
          value: row['client_avg'],
          format: this.dataFormat['client_avg']
        })
        downloadReportRows.push(data)
      })

      this.setDownloadDCReportData({
        name:
          'Retail Mobile Banking Vendor Analytics - Clien Size Firmographics',
        subTitle: this.reportSubTitle,
        yearHeaders: yearHeaders,
        data: downloadReportRows
      })
    }
  }
}
</script>
